export var SAP_INVOICE_FIND_REQUEST = 'SAP_INVOICE_FIND_REQUEST';
export var SAP_INVOICE_FIND_FAILURE = 'SAP_INVOICE_FIND_FAILURE';
export var SAP_INVOICE_FIND_SUCCESS = 'SAP_INVOICE_FIND_SUCCESS';
export var SAP_INVOICE_SET_CURRENT_PAGE = 'SAP_INVOICE_SET_CURRENT_PAGE';
export var SAP_INVOICE_SET_PAGE_COUNT = 'SAP_INVOICE_SET_PAGE_COUNT';
export var SAP_INVOICE_SET_PAGE_SIZE = 'SAP_INVOICE_SET_PAGE_SIZE';
export var SAP_INVOICE_SET_TOTAL_COUNT = 'SAP_INVOICE_SET_TOTAL_COUNT';
export var SAP_INVOICE_HIDE_LOADING_ICON = 'SAP_INVOICE_HIDE_LOADING_ICON';
export var SAP_INVOICE_SHOW_LOADING_ICON = 'SAP_INVOICE_SHOW_LOADING_ICON';
export var SET_SAP_INVOICES = 'SET_SAP_INVOICES';
export var SAP_INVOICE_RECTIFY_REQUEST = 'SAP_INVOICE_RECTIFY_REQUEST';
export var SAP_INVOICE_RECTIFY_REQUEST_SUCCESS = 'SAP_INVOICE_RECTIFY_REQUEST_SUCCESS';
export var SAP_INVOICE_RECTIFY_REQUEST_FAIL = 'SAP_INVOICE_RECTIFY_REQUEST_FAIL';
export var SAP_INVOICE_SET_SAP_INVOICE_TO_RECTIFY = 'SAP_INVOICE_SET_SAP_INVOICE_TO_RECTIFY';
export var SAP_INVOICE_SHOW_RECTIFY_SAP_INVOICE_MODAL = 'SAP_INVOICE_SHOW_RECTIFY_SAP_INVOICE_MODAL';
export var SAP_INVOICE_HIDE_RECTIFY_SAP_INVOICE_MODAL = 'SAP_INVOICE_HIDE_RECTIFY_SAP_INVOICE_MODAL';
export var findSapInvoices = function findSapInvoices(startDate, endDate, pageNumber, pageSize) {
  return {
    type: SAP_INVOICE_FIND_REQUEST,
    payload: {
      startDate: startDate,
      endDate: endDate,
      pageNumber: pageNumber,
      pageSize: pageSize
    }
  };
};
export var findSapInvoicesFailure = function findSapInvoicesFailure(errorMessage) {
  return {
    type: SAP_INVOICE_FIND_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var findSapInvoicesSuccess = function findSapInvoicesSuccess(sapInvoices) {
  return {
    type: SAP_INVOICE_FIND_SUCCESS,
    payload: {
      sapInvoices: sapInvoices.sapInvoices,
      totalCount: sapInvoices.totalCount
    }
  };
};
export var setSapCurrentPage = function setSapCurrentPage(pageNumber) {
  return {
    type: SAP_INVOICE_SET_CURRENT_PAGE,
    payload: pageNumber
  };
};
export var setPageCount = function setPageCount(pageCount) {
  return {
    type: SAP_INVOICE_SET_PAGE_COUNT,
    payload: pageCount
  };
};
export var setPageSize = function setPageSize(pageSize) {
  return {
    type: SAP_INVOICE_SET_PAGE_SIZE,
    payload: pageSize
  };
};
export var setTotalCount = function setTotalCount(totalCount) {
  return {
    type: SAP_INVOICE_SET_TOTAL_COUNT,
    payload: totalCount
  };
};
export var showSapInvoiceLoadingIcon = function showSapInvoiceLoadingIcon() {
  return {
    type: SAP_INVOICE_SHOW_LOADING_ICON
  };
};
export var hideSapInvoiceLoadingIcon = function hideSapInvoiceLoadingIcon() {
  return {
    type: SAP_INVOICE_HIDE_LOADING_ICON
  };
};
export var setSapInvoices = function setSapInvoices(sapInvoices) {
  return {
    type: SET_SAP_INVOICES,
    payload: sapInvoices
  };
};
export var rectifySapInvoice = function rectifySapInvoice(sapInvoiceId) {
  return {
    type: SAP_INVOICE_RECTIFY_REQUEST,
    payload: sapInvoiceId
  };
};
export var rectifySapInvoiceSuccess = function rectifySapInvoiceSuccess() {
  return {
    type: SAP_INVOICE_RECTIFY_REQUEST_SUCCESS
  };
};
export var rectifySapInvoiceFail = function rectifySapInvoiceFail() {
  return {
    type: SAP_INVOICE_RECTIFY_REQUEST_FAIL
  };
};
export var setSapInvoiceToRectify = function setSapInvoiceToRectify(sapInvoice) {
  return {
    type: SAP_INVOICE_SET_SAP_INVOICE_TO_RECTIFY,
    payload: sapInvoice
  };
};
export var showRectifySapInvoiceModal = function showRectifySapInvoiceModal() {
  return {
    type: SAP_INVOICE_SHOW_RECTIFY_SAP_INVOICE_MODAL
  };
};
export var hideRectifySapInvoiceModal = function hideRectifySapInvoiceModal() {
  return {
    type: SAP_INVOICE_HIDE_RECTIFY_SAP_INVOICE_MODAL
  };
};