import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { correctionScreenAddItemToSelection, correctionScreenClearSelection, correctionScreenRemoveItemFromSelection, correctionScreenRemoveSelectedItems, correctionScreenSelectAllItems, correctItem, sendCorrectedItemsRequest, setCorrectionScreenCurrentPage, setCorrectionScreenPageSize, setCorrectionScreenSearchTerm, setErrorFilter, setIsCaptchaValid, setSendCorrectedItemsInProgress } from 'actions/itemCorrection';
import { toggleModalVisibility, toggleSubmitButton } from 'actions/customModal';
import { bindActionCreators } from 'redux';
import { blockCurrentBranchSelection } from 'actions/branch';
import { connect } from 'react-redux';
import ItemCorrectionScreen from './ItemCorrectionScreen';
import { withNavigate } from '../withNavigate';
var mapStateToProps = function mapStateToProps(state) {
  return _objectSpread(_objectSpread(_objectSpread({}, state.itemCorrection), state.branch), {}, {
    isCaptchaModalShowable: state.customModal.isModalShowable,
    isCaptchaValid: state.itemCorrection.isCaptchaValid
  });
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    blockCurrentBranchSelection: blockCurrentBranchSelection,
    correctionScreenAddItemToSelection: correctionScreenAddItemToSelection,
    correctionScreenClearSelection: correctionScreenClearSelection,
    correctItem: correctItem,
    correctionScreenRemoveItemFromSelection: correctionScreenRemoveItemFromSelection,
    correctionScreenRemoveSelectedItems: correctionScreenRemoveSelectedItems,
    correctionScreenSelectAllItems: correctionScreenSelectAllItems,
    sendCorrectedItemsRequest: sendCorrectedItemsRequest,
    setErrorFilter: setErrorFilter,
    setCorrectionScreenCurrentPage: setCorrectionScreenCurrentPage,
    setCorrectionScreenSearchTerm: setCorrectionScreenSearchTerm,
    setCorrectionScreenPageSize: setCorrectionScreenPageSize,
    setSendCorrectedItemsInProgress: setSendCorrectedItemsInProgress,
    toggleModalVisibility: toggleModalVisibility,
    setIsCaptchaValid: setIsCaptchaValid,
    toggleSubmitButton: toggleSubmitButton
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(ItemCorrectionScreen));