/**
 * Get cookie
 * @param {string} name
 */
var getCookie = function getCookie(name) {
  var cookieArr = document.cookie.split(';');
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');
    if (name == cookiePair[0].trim()) {
      return cookiePair[1];
    }
  }
  return null;
};

/**
 * Set cookie
 * @param {string} name
 * @param {string} value
 * @param {int} expireDays
 */
var setCookie = function setCookie(name, value, expireDays) {
  var date = new Date();
  date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

/**
 * Delete cookie
 * @param {string} name
 */
var deleteCookie = function deleteCookie(name) {
  setCookie(name, null, -1);
};
export default {
  get: getCookie,
  set: setCookie,
  "delete": deleteCookie
};