import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { BRANCH_USER_ADD, BRANCH_USER_FIND_FAILURE, BRANCH_USER_FIND_SUCCESS, setBranchUsers } from 'actions/branchUser';
import { userRole } from 'utils/enums';
function branchUser(store) {
  return function (next) {
    return function (action) {
      switch (action.type) {
        case BRANCH_USER_FIND_FAILURE:
          {
            next(setBranchUsers([]));
            break;
          }
        case BRANCH_USER_FIND_SUCCESS:
          {
            // eslint-disable-next-line no-case-declarations
            var updatedUsers = [];
            action.payload.map(function (u) {
              var updatedUser = u;
              updatedUser.fullName = u.lastName + ' ' + u.firstName;
              updatedUser.code = u.firstName.charAt(0).toUpperCase() + u.lastName.charAt(0).toUpperCase();
              updatedUser.userRole = userRole.properties[u.roleIds[0]];
              updatedUsers.push(updatedUser);
            });
            next(setBranchUsers(updatedUsers));
            break;
          }
        case BRANCH_USER_ADD:
          {
            // eslint-disable-next-line no-case-declarations
            var currentBranch = store.getState().branch.currentBranch,
              branchUsers = store.getState().branchUser.branchUsers;
            var newUser = {
              code: action.payload.firstName.charAt(0).toUpperCase() + action.payload.lastName.charAt(0).toUpperCase(),
              employerId: currentBranch.employerId,
              firstName: action.payload.firstName,
              fullName: action.payload.lastName + ' ' + action.payload.firstName,
              id: action.payload.userId,
              lastName: action.payload.lastName,
              phoneNumber: action.payload.phoneNumber,
              roleIds: action.payload.roleIds.map(function (id) {
                return Number.parseInt(id);
              }),
              status: 0,
              userRole: userRole.properties[action.payload.roleIds[0]],
              username: action.payload.username
            };
            next(setBranchUsers([].concat(_toConsumableArray(branchUsers), [newUser])));
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
export default branchUser;