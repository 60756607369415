export var AUDIT_SET_STATE = 'AUDIT_SET_STATE';
export var AUDIT_SET_ENTRIES = 'AUDIT_SET_ENTRIES';
export var AUDIT_SET_FILTER = 'AUDIT_SET_FILTER';
export var AUDIT_SHOW_LOADING_ICON = 'AUDIT_SHOW_LOADING_ICON';
export var AUDIT_HIDE_LOADING_ICON = 'AUDIT_HIDE_LOADING_ICON';
export var AUDIT_LOAD = 'AUDIT_LOAD';
export var setAuditState = function setAuditState(auditState) {
  return {
    type: AUDIT_SET_STATE,
    payload: auditState
  };
};
export var setAuditEntries = function setAuditEntries(data) {
  return {
    type: AUDIT_SET_ENTRIES,
    payload: data
  };
};
export var setAuditFilter = function setAuditFilter(filter) {
  return {
    type: AUDIT_SET_FILTER,
    payload: filter
  };
};
export var showAuditLoadingIcon = function showAuditLoadingIcon() {
  return {
    type: AUDIT_SHOW_LOADING_ICON
  };
};
export var hideAuditLoadingIcon = function hideAuditLoadingIcon() {
  return {
    type: AUDIT_HIDE_LOADING_ICON
  };
};
export var loadAudit = function loadAudit(filter) {
  return {
    type: AUDIT_LOAD,
    payload: filter
  };
};