var TOKEN_LIFE_SPAN = 2 * 60 * 1000;
export var reCaptchaAction = {
  MEAL_CARDS_ORDER: 'meal_cards_order',
  LOGIN_SUBMIT_USERNAME: 'login_submit_username',
  SUBMIT_PASSWORD: 'submit_password',
  CHANGE_PASSWORD_SUBMIT_CURRENT_PASSWORD: 'change_password_submit_current_password',
  SUBMIT_SMS_OTP: 'submit_sms_otp',
  FORGOT_PASSWORD_SUBMIT_PHONE_NUMBER: 'forgot_password_submit_phone_number',
  ENROLLMENT_BULK_FILE_SUBMIT: 'enrollment_bulk_file_submit'
};
export var getReCaptchaToken = function getReCaptchaToken(userAction) {
  return new Promise(function (resolve, reject) {
    // eslint-disable-next-line no-undef
    grecaptcha.ready(function () {
      var siteKey = window.localStorage.getItem('siteKey');
      // eslint-disable-next-line no-undef
      grecaptcha.execute(siteKey, {
        action: userAction
      }).then(function (token) {
        resolve(token);
      })["catch"](function (error) {
        reject(error);
      });
    });
  });
};
export var isReCaptchaTokenExpired = function isReCaptchaTokenExpired(token, tokenGenerationTime) {
  if (!token) {
    return true;
  }
  var currentTime = new Date().getTime();
  var timeDifference = currentTime - tokenGenerationTime;
  return timeDifference > TOKEN_LIFE_SPAN;
};