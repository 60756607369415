import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { CARD_ADD_ITEM_TO_SELECTION, CARD_CLEAR_SELECTION, CARD_CONFIRMATION_HIDE_MODAL, CARD_CONFIRMATION_SHOW_MODAL, CARD_REISSUE_HIDE_CONFIRMATION_MODAL, CARD_REISSUE_SHOW_CONFIRMATION_MODAL, CARD_REMOVE_ITEM_FROM_SELECTION, CARD_SELECT_ALL, CARD_SET_CARD_FILTERS, CARD_SET_CARDS, CARD_SET_CURRENT_PAGE, CARD_SET_PAGE_COUNT, CARD_SET_PAGE_SIZE, CARD_SET_SEND_REISSUE_CARD_IN_PROGRESS, CARD_SET_TOTAL_COUNT, CHANGE_TICKET_VALUE_HIDE_MODAL, CHANGE_TICKET_VALUE_SHOW_MODAL, MIGRATE_CARDS_HIDE_MODAL, MIGRATE_CARDS_SHOW_MODAL, REISSUE_CARD_HIDE_MODAL, REISSUE_CARD_SET_MODAL_ERROR, REISSUE_CARD_SHOW_MODAL, REISSUE_PIN_HIDE_MODAL, REISSUE_PIN_SET_MODAL_ERROR, REISSUE_PIN_SHOW_MODAL, SET_CARDS_LOADED_FLAG, SET_SELECTED_CARDS } from 'actions/card';
import { cardStatus, selectionType } from 'utils/enums';
export default function card(state, action) {
  state = state || {
    cards: null,
    selectState: selectionType.none,
    selectedCards: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    firstOrLastName: null,
    cardReissueModalShow: {
      displayed: false,
      cardId: null
    }
  };
  switch (action.type) {
    case CARD_SET_CARDS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cards: action.payload.map(function (card) {
            return Object.assign({}, card, {
              status: isNaN(card.statusId) ? card.statusId : cardStatus.properties[card.statusId]
            });
          })
        });
      }
    case CARD_ADD_ITEM_TO_SELECTION:
      {
        var updatedSelectState;
        if (state.selectState !== selectionType.all && state.selectedCards.length + 1 === state.totalCount) {
          updatedSelectState = selectionType.all;
        } else if (state.selectState === selectionType.none) {
          updatedSelectState = selectionType.multiple;
        } else {
          updatedSelectState = state.selectState;
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedCards: state.selectState !== selectionType.all ? [].concat(_toConsumableArray(state.selectedCards), [action.payload]) : [],
          selectState: updatedSelectState
        });
      }
    case CARD_REMOVE_ITEM_FROM_SELECTION:
      {
        var isSelectStateDifferentThanMultiple = state.selectState !== selectionType.multiple;
        var selectedCardsCount = state.selectedCards.length;
        var _updatedSelectState = isSelectStateDifferentThanMultiple && selectedCardsCount > 1 ? selectionType.multiple : isSelectStateDifferentThanMultiple && selectedCardsCount <= 1 ? selectionType.none : !isSelectStateDifferentThanMultiple && selectedCardsCount > 1 ? state.selectState : selectionType.none;
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedCards: state.selectedCards.filter(function (card) {
            return card.cardId !== action.payload.cardId;
          }),
          selectState: _updatedSelectState
        });
      }
    case CARD_SELECT_ALL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedCards: [],
          selectState: selectionType.all
        });
      }
    case CARD_CLEAR_SELECTION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedCards: [],
          selectState: selectionType.none
        });
      }
    case SET_SELECTED_CARDS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedCards: action.payload.cards || [],
          selectState: action.payload.selectionType !== null && action.payload.selectionType !== undefined ? action.payload.selectionType : selectionType.none
        });
      }
    case CARD_SET_CURRENT_PAGE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          currentPage: action.payload
        });
      }
    case CARD_SET_PAGE_COUNT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          pageCount: action.payload
        });
      }
    case CARD_SET_PAGE_SIZE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          pageSize: action.payload
        });
      }
    case CARD_SET_TOTAL_COUNT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          totalCount: action.payload
        });
      }
    case REISSUE_CARD_SHOW_MODAL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cardReissueModalShow: {
            displayed: true,
            cardId: action.payload
          }
        });
      }
    case REISSUE_CARD_HIDE_MODAL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cardReissueModalShow: {
            displayed: false,
            cardId: null
          },
          cardReissueModalError: null
        });
      }
    case REISSUE_PIN_SHOW_MODAL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          pinReissueModalShow: {
            displayed: true,
            cardId: action.payload
          }
        });
      }
    case REISSUE_PIN_HIDE_MODAL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          pinReissueModalShow: {
            displayed: false,
            cardId: null
          },
          pinReissueModalError: null
        });
      }
    case MIGRATE_CARDS_SHOW_MODAL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cardMigrationModalShow: true
        });
      }
    case MIGRATE_CARDS_HIDE_MODAL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cardMigrationModalShow: false
        });
      }
    case CHANGE_TICKET_VALUE_SHOW_MODAL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          changeTicketValueModalShow: true
        });
      }
    case CHANGE_TICKET_VALUE_HIDE_MODAL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          changeTicketValueModalShow: false
        });
      }
    case SET_CARDS_LOADED_FLAG:
      return _objectSpread(_objectSpread({}, state), {}, {
        cardsLoaded: action.payload
      });
    case REISSUE_CARD_SET_MODAL_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        cardReissueModalError: action.payload
      });
    case REISSUE_PIN_SET_MODAL_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        pinReissueModalError: action.payload
      });
    case CARD_CONFIRMATION_SHOW_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        cardConfirmationIsVisible: true
      });
    case CARD_CONFIRMATION_HIDE_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        cardConfirmationIsVisible: false
      });
    case CARD_REISSUE_SHOW_CONFIRMATION_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        cardReissueConfirmationIsVisible: true
      });
    case CARD_REISSUE_HIDE_CONFIRMATION_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        cardReissueConfirmationIsVisible: false
      });
    case CARD_SET_CARD_FILTERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        filters: _objectSpread(_objectSpread({}, state.filters), action.payload)
      });
    case CARD_SET_SEND_REISSUE_CARD_IN_PROGRESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendReissueCardInProgress: action.payload
      });
    default:
      {
        return state;
      }
  }
}