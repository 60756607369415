import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import enrollmentFileEntrySlice from '../features/enrollment-files/view-enrollment-file-entries/enrollmentFileEntrySlice';
import middlewares from 'middlewares';
import reducers from 'reducers';
import { thunk } from 'redux-thunk';
var initialState = {
  card: {
    cards: null,
    selectState: 'none',
    selectedCards: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    filters: {
      firstOrLastName: '',
      cnp: '',
      statusId: null,
      ticketValue: '',
      orderId: null
    },
    correctionScreenShow: false
  },
  branch: {
    branches: [],
    cities: [],
    localities: [],
    users: [],
    isModalVisibile: false,
    isUpsertBranchError: false,
    upsertBranchErrorMessage: ''
  },
  order: {
    orders: [],
    ordersLoaded: true
  },
  branchUser: {
    branchUsers: [],
    isModalWithUsersVisible: 0
  },
  manager: {
    managers: null,
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    searchTerm: null,
    modalServerError: '',
    isModalVisible: false
  },
  sapInvoice: {
    sapInvoices: null,
    currentPage: 1,
    pageCount: 1,
    pageSize: 10
  }
};
var store = configureStore({
  reducer: combineReducers(_objectSpread(_objectSpread({}, reducers), {}, {
    enrollmentFileEntry: enrollmentFileEntrySlice
  })),
  middleware: function middleware() {
    return [].concat(_toConsumableArray(middlewares), [thunk]);
  },
  preloadedState: initialState
});
export default store;