import { generateSmsTokenRequest, generateXsrfToken, saveUserInformation, setLoginError, setOtpCaptcha, setPasswordCaptcha, setPhoneNumberCaptcha, setSmsToken, setUsernameCaptcha } from 'actions/user';
import { USER_CHANGE_PASSWORD_FAILURE, USER_EXPIRED_PASSWORD, USER_FORGOT_PASSWORD_SUCCESS, USER_GENERATE_SMS_TOKEN_SUCCESS, USER_GET_USER_DATA_SUCCESS, USER_SUBMIT_PASSWORD_FAILURE, USER_SUBMIT_PASSWORD_SUCCESS, USER_SUBMIT_PHONE_NUMBER_FAILURE, USER_SUBMIT_PHONE_NUMBER_SUCCESS, USER_SUBMIT_SMS_TOKEN_FAILURE, USER_SUBMIT_SMS_TOKEN_SUCCESS, USER_SUBMIT_USERNAME_FAILURE, USER_SUBMIT_USERNAME_SUCCESS, USER_VERIFY_PASSWORD_FAILURE, USER_VERIFY_PASSWORD_SUCCESS } from 'actions/user';
import errorCodes from 'utils/errorCodes';
function user(_ref) {
  var getState = _ref.getState,
    dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      switch (action.type) {
        case USER_GET_USER_DATA_SUCCESS:
          {
            next(saveUserInformation(action.payload));
            break;
          }
        case USER_GENERATE_SMS_TOKEN_SUCCESS:
          {
            var _action$payload = action.payload,
              otpCode = _action$payload.otpCode,
              maskedPhoneNumber = _action$payload.maskedPhoneNumber,
              remainingSeconds = _action$payload.remainingSeconds;
            next(setSmsToken(otpCode));
            next(saveUserInformation({
              maskedPhoneNumber: maskedPhoneNumber
            }));
            next(saveUserInformation({
              remainingSeconds: remainingSeconds
            }));
            next(saveUserInformation({
              username: getState().user.username
            }));
            break;
          }
        case USER_VERIFY_PASSWORD_SUCCESS:
          {
            next(saveUserInformation({
              username: getState().user.username,
              isChangePassword: true
            }));
            next(setLoginError(''));
            next(setPasswordCaptcha({
              captcha: null,
              error: null
            }));
            dispatch(generateSmsTokenRequest());
            break;
          }
        case USER_VERIFY_PASSWORD_FAILURE:
          {
            if (action.payload === errorCodes.changePasswordInvalidCaptcha || action.payload === errorCodes.changePasswordBruteForceAttempt || action.payload === errorCodes.changePasswordBotDetected || (action.payload === errorCodes.invalidPassword || action.payload === errorCodes.serverError) && getState().user.passwordCaptcha) {
              next(setPasswordCaptcha({
                captcha: Math.random(),
                error: action.payload
              }));
            } else next(setLoginError(action.payload));
            break;
          }
        case USER_CHANGE_PASSWORD_FAILURE:
          {
            next(setLoginError(action.payload));
            break;
          }
        case USER_SUBMIT_PASSWORD_SUCCESS:
          {
            next(setLoginError(''));
            next(setPasswordCaptcha({
              captcha: null,
              error: null
            }));
            dispatch(generateSmsTokenRequest());
            break;
          }
        case USER_SUBMIT_PASSWORD_FAILURE:
          {
            if (action.payload === errorCodes.enterPasswordInvalidCaptcha || action.payload === errorCodes.enterPasswordBruteForceAttempt || action.payload === errorCodes.enterPasswordBotDetected || (action.payload === errorCodes.invalidPassword || action.payload === errorCodes.serverError) && getState().user.passwordCaptcha) {
              next(setPasswordCaptcha({
                captcha: Math.random(),
                error: action.payload
              }));
            } else {
              next(setLoginError(action.payload));
            }
            break;
          }
        case USER_SUBMIT_USERNAME_FAILURE:
          {
            if (action.payload === errorCodes.enterUsernameInvalidCaptcha || action.payload === errorCodes.enterUsernameBruteForceAttempt || action.payload === errorCodes.enterUsernameBotDetected || action.payload === errorCodes.invalidUser && getState().user.usernameCaptcha) {
              next(setUsernameCaptcha({
                captcha: Math.random(),
                error: action.payload
              }));
            } else {
              next(setLoginError(action.payload));
            }
            break;
          }
        case USER_SUBMIT_SMS_TOKEN_SUCCESS:
          {
            var userInfo = {
              smsTokenSubmitted: true
            };
            if (getState().user.isForgotPassword) {
              userInfo.isForgotPassword = true;
            }
            if (getState().user.isChangePassword) {
              userInfo.isChangePassword = true;
            }
            dispatch(generateXsrfToken());
            userInfo.username = getState().user.username;
            next(saveUserInformation(userInfo));
            next(setOtpCaptcha({
              captcha: null,
              error: null
            }));
            window.localStorage.removeItem('userLoggedOut');
            break;
          }
        case USER_SUBMIT_SMS_TOKEN_FAILURE:
          {
            if (action.payload === errorCodes.enterOtpInvalidCaptcha || action.payload === errorCodes.enterOtpBruteForceAttempt || action.payload === errorCodes.enterOtpBotDetected || (action.payload === errorCodes.invalidOtp || action.payload === errorCodes.serverError) && getState().user.otpCaptcha) {
              next(setOtpCaptcha({
                captcha: Math.random(),
                error: action.payload
              }));
            } else {
              next(setLoginError(action.payload));
            }
            break;
          }
        case USER_SUBMIT_USERNAME_SUCCESS:
          {
            var userStatus = action.payload.userStatus;
            next(saveUserInformation({
              userStatus: userStatus
            }));
            next(setUsernameCaptcha({
              captcha: null,
              error: null
            }));
            break;
          }
        case USER_FORGOT_PASSWORD_SUCCESS:
          {
            var _maskedPhoneNumber = action.payload.maskedPhoneNumber;
            next(saveUserInformation({
              maskedPhoneNumber: _maskedPhoneNumber
            }));
            break;
          }
        case USER_SUBMIT_PHONE_NUMBER_SUCCESS:
          {
            next(setPhoneNumberCaptcha({
              captcha: null,
              error: null
            }));
            next(saveUserInformation({
              isForgotPassword: true
            }));
            break;
          }
        case USER_SUBMIT_PHONE_NUMBER_FAILURE:
          {
            if (action.payload === errorCodes.enterPhoneNumberInvalidCaptcha || action.payload === errorCodes.enterPhoneNumberBruteForceAttempt || action.payload === errorCodes.enterPhoneNumberBotDetected || (action.payload === errorCodes.invalidPhoneNumber || action.payload === errorCodes.serverError) && getState().user.phoneNumberCaptcha) {
              next(setPhoneNumberCaptcha({
                captcha: Math.random(),
                error: action.payload
              }));
            } else next(setLoginError(action.payload));
            break;
          }
        case USER_EXPIRED_PASSWORD:
          {
            var _action$payload2 = action.payload,
              _userStatus = _action$payload2.userStatus,
              errorMessage = _action$payload2.errorMessage;
            next(saveUserInformation({
              userStatus: _userStatus
            }));
            next(setUsernameCaptcha({
              captcha: null,
              error: null
            }));
            next(setLoginError(errorMessage));
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
export default user;