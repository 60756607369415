import audit from './audit';
import branch from './branch';
import branchUser from './branchUser';
import card from './card';
import customModal from './customModal';
import enrollmentFiles from './enrollmentFiles';
import itemCorrection from './itemCorrection';
import loadingSpinner from './loadingSpinner';
import locations from './locations';
import manager from './manager';
import order from './order';
import sapInvoice from './sapInvoice';
import toast from './toast';
import upload from './upload';
import user from './user';
export default {
  audit: audit,
  user: user,
  card: card,
  customModal: customModal,
  branch: branch,
  order: order,
  loadingSpinner: loadingSpinner,
  locations: locations,
  upload: upload,
  toast: toast,
  itemCorrection: itemCorrection,
  branchUser: branchUser,
  manager: manager,
  sapInvoice: sapInvoice,
  enrollmentFiles: enrollmentFiles
};