import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SAP_INVOICE_HIDE_LOADING_ICON, SAP_INVOICE_HIDE_RECTIFY_SAP_INVOICE_MODAL, SAP_INVOICE_SET_CURRENT_PAGE, SAP_INVOICE_SET_PAGE_COUNT, SAP_INVOICE_SET_PAGE_SIZE, SAP_INVOICE_SET_SAP_INVOICE_TO_RECTIFY, SAP_INVOICE_SET_TOTAL_COUNT, SAP_INVOICE_SHOW_LOADING_ICON, SAP_INVOICE_SHOW_RECTIFY_SAP_INVOICE_MODAL, SET_SAP_INVOICES } from '../actions/sapInvoice';
export default function sapInvoice(state, action) {
  state = state || {
    sapInvoices: null,
    isLoading: false,
    currentPage: 1,
    pageCount: 1,
    pageSize: 10
  };
  switch (action.type) {
    case SAP_INVOICE_HIDE_LOADING_ICON:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case SAP_INVOICE_SHOW_LOADING_ICON:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case SET_SAP_INVOICES:
      return _objectSpread(_objectSpread({}, state), {}, {
        sapInvoices: action.payload
      });
    case SAP_INVOICE_SET_CURRENT_PAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentPage: action.payload
      });
    case SAP_INVOICE_SET_PAGE_COUNT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          pageCount: action.payload
        });
      }
    case SAP_INVOICE_SET_PAGE_SIZE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          pageSize: action.payload
        });
      }
    case SAP_INVOICE_SET_TOTAL_COUNT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          totalCount: action.payload
        });
      }
    case SAP_INVOICE_SET_SAP_INVOICE_TO_RECTIFY:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          sapInvoiceToRectify: action.payload
        });
      }
    case SAP_INVOICE_SHOW_RECTIFY_SAP_INVOICE_MODAL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          showRectifyModal: true
        });
      }
    case SAP_INVOICE_HIDE_RECTIFY_SAP_INVOICE_MODAL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          showRectifyModal: false
        });
      }
    default:
      return state;
  }
}