import { correctionScreenClearSelection, ITEM_CORRECTION_PAGINATE_ITEMS, ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_FAILURE, ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_SUCCESS, ITEM_CORRECTION_SET_CURRENT_PAGE, ITEM_CORRECTION_SET_ERROR_FILTER, ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED, ITEM_CORRECTION_SET_PAGE_SIZE, ITEM_CORRECTION_SET_SEARCH_TERM, ITEM_CORRETION_REMOVE_SELECTED_ITEMS, paginateItemsToBeCorrected, setCorrectionScreenCurrentPage, setCorrectionScreenItems, setCorrectionScreenPageCount, setCorrectionScreenSearchTerm, setCorrectionScreenTotalCount, setErrorFilter, setFilteredItems, setItemsToBeCorrected, setSendCorrectedItemsInProgress } from 'actions/itemCorrection';
import { errorFilter, fileUploadType } from 'utils/enums';
import errorCodes from 'utils/errorCodes';
import { setValidationStatus } from 'actions/upload';
import { showToast } from 'actions/toast';
import { toggleModalVisibility } from 'actions/customModal';
function itemCorrection(store) {
  return function (next) {
    return function (action) {
      switch (action.type) {
        case ITEM_CORRECTION_SET_ERROR_FILTER:
          {
            var _store$getState$itemC = store.getState().itemCorrection,
              pageSize = _store$getState$itemC.pageSize,
              searchTerm = _store$getState$itemC.searchTerm,
              itemsToBeCorrected = _store$getState$itemC.itemsToBeCorrected;
            var filteredItems = action.payload === errorFilter.onlyErrors ? itemsToBeCorrected.filter(function (item) {
              return item.errors.length > 0;
            }) : itemsToBeCorrected;
            next(setCorrectionScreenCurrentPage(1));
            next(setFilteredItems(filteredItems));
            next(correctionScreenClearSelection());
            store.dispatch(paginateItemsToBeCorrected(filteredItems, 1, pageSize, searchTerm));
            break;
          }
        case ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED:
          {
            var _pageSize = store.getState().itemCorrection.pageSize;
            next(setErrorFilter(errorFilter.all));
            next(setCorrectionScreenSearchTerm(''));
            next(setCorrectionScreenCurrentPage(1));
            next(correctionScreenClearSelection());
            next(setFilteredItems(action.payload.items));
            action.payload && store.dispatch(paginateItemsToBeCorrected(action.payload.items, 1, _pageSize, '', errorFilter.all));
            var searchBarInput = document.getElementById('search-bar-input-cards');
            searchBarInput ? searchBarInput.value = '' : {};
            break;
          }
        case ITEM_CORRECTION_PAGINATE_ITEMS:
          {
            var pageNumber = action.payload.pageNumber,
              size = action.payload.pageSize,
              term = action.payload.lastNameOrCnp,
              items = action.payload.items,
              pageCount = pageNumber;
            var uploadTypeId = store.getState().itemCorrection.uploadTypeId;
            switch (uploadTypeId) {
              case fileUploadType.cardsImport:
              case fileUploadType.cardsDelete:
              case fileUploadType.cardsImportAllBranches:
              case fileUploadType.cardsModifyTicketValue:
                items = term && term !== '' ? items.filter(function (item) {
                  return item.lastName.toLowerCase().startsWith(term) || item.cnp.startsWith(term);
                }) : items;
                break;
              case fileUploadType.branchesImport:
                items = term && term !== '' ? items.filter(function (item) {
                  return item.name.toLowerCase().includes(term);
                }) : items;
                break;
            }
            pageCount = Math.ceil(items.length / size);
            if (pageNumber > pageCount) {
              pageNumber = pageCount;
            }
            if (items && items.length > 0) {
              next(setCorrectionScreenItems(items.slice((pageNumber - 1) * size, pageNumber * size)));
              next(setCorrectionScreenPageCount(pageCount));
              next(setCorrectionScreenCurrentPage(pageNumber));
              next(setCorrectionScreenTotalCount(items.length));
            } else {
              next(setCorrectionScreenItems([]));
              next(setCorrectionScreenPageCount(1));
              next(setCorrectionScreenTotalCount(0));
            }
            break;
          }
        case ITEM_CORRECTION_SET_CURRENT_PAGE:
          {
            var _store$getState$itemC2 = store.getState().itemCorrection,
              _pageSize2 = _store$getState$itemC2.pageSize,
              _searchTerm = _store$getState$itemC2.searchTerm,
              _filteredItems = _store$getState$itemC2.filteredItems,
              filter = _store$getState$itemC2.filter;
            store.dispatch(paginateItemsToBeCorrected(_filteredItems, action.payload, _pageSize2, _searchTerm, filter));
            break;
          }
        case ITEM_CORRECTION_SET_SEARCH_TERM:
          {
            var _store$getState$itemC3 = store.getState().itemCorrection,
              _pageSize3 = _store$getState$itemC3.pageSize,
              _filteredItems2 = _store$getState$itemC3.filteredItems,
              _filter = _store$getState$itemC3.filter;
            store.dispatch(paginateItemsToBeCorrected(_filteredItems2, 1, _pageSize3, action.payload, _filter));
            next(setCorrectionScreenCurrentPage(1));
            next(correctionScreenClearSelection());
            break;
          }
        case ITEM_CORRECTION_SET_PAGE_SIZE:
          {
            var _store$getState$itemC4 = store.getState().itemCorrection,
              _searchTerm2 = _store$getState$itemC4.searchTerm,
              _filteredItems3 = _store$getState$itemC4.filteredItems,
              _filter2 = _store$getState$itemC4.filter;
            store.dispatch(paginateItemsToBeCorrected(_filteredItems3, 1, action.payload, _searchTerm2, _filter2));
            next(setCorrectionScreenCurrentPage(1));
            break;
          }
        case ITEM_CORRETION_REMOVE_SELECTED_ITEMS:
          {
            var _store$getState$itemC5 = store.getState().itemCorrection,
              currentPage = _store$getState$itemC5.currentPage,
              _pageSize4 = _store$getState$itemC5.pageSize,
              _searchTerm3 = _store$getState$itemC5.searchTerm,
              _itemsToBeCorrected = _store$getState$itemC5.itemsToBeCorrected,
              _filteredItems4 = _store$getState$itemC5.filteredItems,
              selectedItems = _store$getState$itemC5.selectedItems,
              _uploadTypeId = _store$getState$itemC5.uploadTypeId;
            var selectedItemsIds = selectedItems.map(function (si) {
                return si.cardId;
              }),
              remainingItems = _itemsToBeCorrected.filter(function (item) {
                return !selectedItemsIds.includes(item.id);
              }),
              remainingFilteredItems = _filteredItems4.filter(function (item) {
                return !selectedItemsIds.includes(item.id);
              });
            next(setItemsToBeCorrected(remainingItems, _uploadTypeId));
            next(setFilteredItems(remainingFilteredItems));
            next(correctionScreenClearSelection());
            store.dispatch(paginateItemsToBeCorrected(remainingFilteredItems, currentPage, _pageSize4, _searchTerm3));
            break;
          }
        case ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_FAILURE:
          {
            next(toggleModalVisibility(false));
            next(setValidationStatus(false));
            next(setSendCorrectedItemsInProgress(false));
            if (action.payload.items) {
              store.dispatch(setItemsToBeCorrected(action.payload.items, action.payload.uploadTypeId));
            } else {
              store.dispatch(showToast({
                type: 'error',
                text: errorCodes.genericErrorMessage
              }));
            }
            break;
          }
        case ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_SUCCESS:
          {
            next(toggleModalVisibility(false));
            next(setValidationStatus(true));
            next(setSendCorrectedItemsInProgress(false));
            store.dispatch(setCorrectionScreenSearchTerm(''));
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
export default itemCorrection;