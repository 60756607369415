import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { BRANCH_USER_HIDE_MODAL_WITH_USERS, BRANCH_USER_SHOW_MODAL_WITH_USERS, SET_BRANCH_USERS } from 'actions/branchUser';
export default function branchUser(state, action) {
  state = state || {
    branchUsers: [],
    isModalWithUsersVisible: 0
  };
  switch (action.type) {
    case SET_BRANCH_USERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        branchUsers: action.payload
      });
    case BRANCH_USER_SHOW_MODAL_WITH_USERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isModalWithUsersVisible: action.payload
      });
    case BRANCH_USER_HIDE_MODAL_WITH_USERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isModalWithUsersVisible: 0
      });
    default:
      return state;
  }
}