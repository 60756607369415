import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { correctionScreenClearSelection, ENROLLMENT_FILES_ITEM_CORRECTION_CORRECT_ITEM, ENROLLMENT_FILES_ITEM_CORRECTION_PAGINATE_ITEMS, ENROLLMENT_FILES_ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_FAILURE, ENROLLMENT_FILES_ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_SUCCESS, ENROLLMENT_FILES_ITEM_CORRECTION_SET_CURRENT_PAGE, ENROLLMENT_FILES_ITEM_CORRECTION_SET_ERROR_FILTER, ENROLLMENT_FILES_ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED, ENROLLMENT_FILES_ITEM_CORRECTION_SET_PAGE_SIZE, ENROLLMENT_FILES_ITEM_CORRECTION_SET_SEARCH_TERM, ENROLLMENT_FILES_ITEM_CORRETION_REMOVE_SELECTED_ITEMS, paginateItemsToBeCorrected, setCorrectionScreenCurrentPage, setCorrectionScreenItems, setCorrectionScreenPageCount, setCorrectionScreenSearchTerm, setCorrectionScreenTotalCount, setErrorFilter, setFilteredItems, setItemsToBeCorrected, setSendCorrectedItemsInProgress } from 'actions/enrollmentFilesItemCorrection';
import errorCodes from 'utils/errorCodes';
import { errorFilter } from 'utils/enums';
import { setValidationStatus } from 'actions/upload';
import { showToast } from 'actions/toast';
import { toggleModalVisibility } from '../actions/customModal';
function enrollmentFilesItemCorrection(store) {
  return function (next) {
    return function (action) {
      switch (action.type) {
        case ENROLLMENT_FILES_ITEM_CORRECTION_CORRECT_ITEM:
          {
            var _store$getState$enrol = store.getState().enrollmentFiles.itemCorrection,
              fileEntries = _store$getState$enrol.fileEntries,
              filteredItems = _store$getState$enrol.filteredItems;
            var items = filteredItems,
              itemIndex = items.findIndex(function (item) {
                return item.id === action.payload.id;
              });
            items.splice(itemIndex, 1, action.payload);
            filteredItems = _toConsumableArray(items);
            store.dispatch(setFilteredItems(filteredItems));
            items = fileEntries, itemIndex = items.findIndex(function (item) {
              return item.id === action.payload.id;
            });
            items.splice(itemIndex, 1, action.payload);
            fileEntries = _toConsumableArray(items);
            store.dispatch(setCorrectionScreenItems(fileEntries));
            break;
          }
        case ENROLLMENT_FILES_ITEM_CORRECTION_SET_ERROR_FILTER:
          {
            var _store$getState$enrol2 = store.getState().enrollmentFiles.itemCorrection,
              pageSize = _store$getState$enrol2.pageSize,
              searchTerm = _store$getState$enrol2.searchTerm,
              itemsToBeCorrected = _store$getState$enrol2.itemsToBeCorrected;
            var _filteredItems = action.payload === errorFilter.onlyErrors ? itemsToBeCorrected.filter(function (item) {
              return item.errors.length > 0 && item.errors.filter(function (e) {
                return e.errorMessage;
              }).length > 0;
            }) : itemsToBeCorrected;
            next(setCorrectionScreenCurrentPage(1));
            next(setFilteredItems(_filteredItems));
            next(correctionScreenClearSelection());
            store.dispatch(paginateItemsToBeCorrected(_filteredItems, 1, pageSize, searchTerm));
            break;
          }
        case ENROLLMENT_FILES_ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED:
          {
            var _pageSize = store.getState().enrollmentFiles.itemCorrection.pageSize;
            next(setErrorFilter(errorFilter.all));
            next(setCorrectionScreenSearchTerm(''));
            next(setCorrectionScreenCurrentPage(1));
            next(correctionScreenClearSelection());
            next(setFilteredItems(action.payload.items));
            action.payload && store.dispatch(paginateItemsToBeCorrected(action.payload.items, 1, _pageSize, '', errorFilter.all));
            var searchBarInput = document.getElementById('search-bar-input-cards');
            searchBarInput ? searchBarInput.value = '' : {};
            break;
          }
        case ENROLLMENT_FILES_ITEM_CORRECTION_PAGINATE_ITEMS:
          {
            var pageNumber = action.payload.pageNumber,
              size = action.payload.pageSize,
              _items = action.payload.items,
              pageCount = pageNumber,
              _searchTerm = action.payload.lastNameOrCnp;
            _items = _searchTerm && _searchTerm !== '' ? _items.filter(function (item) {
              return item.lastName.toLowerCase().startsWith(_searchTerm) || item.cnp.startsWith(_searchTerm);
            }) : _items;
            pageCount = Math.ceil(_items.length / size);
            if (pageNumber > pageCount) {
              pageNumber = pageCount;
            }
            if (_items && _items.length > 0) {
              next(setCorrectionScreenItems(_items.slice((pageNumber - 1) * size, pageNumber * size)));
              next(setCorrectionScreenPageCount(pageCount));
              next(setCorrectionScreenCurrentPage(pageNumber));
              next(setCorrectionScreenTotalCount(_items.length));
            } else {
              next(setCorrectionScreenItems([]));
              next(setCorrectionScreenPageCount(1));
              next(setCorrectionScreenTotalCount(0));
            }
            break;
          }
        case ENROLLMENT_FILES_ITEM_CORRECTION_SET_CURRENT_PAGE:
          {
            var _store$getState$enrol3 = store.getState().enrollmentFiles.itemCorrection,
              _pageSize2 = _store$getState$enrol3.pageSize,
              _searchTerm2 = _store$getState$enrol3.searchTerm,
              _filteredItems2 = _store$getState$enrol3.filteredItems,
              filter = _store$getState$enrol3.filter;
            store.dispatch(paginateItemsToBeCorrected(_filteredItems2, action.payload, _pageSize2, _searchTerm2, filter));
            break;
          }
        case ENROLLMENT_FILES_ITEM_CORRECTION_SET_SEARCH_TERM:
          {
            var _store$getState$enrol4 = store.getState().enrollmentFiles.itemCorrection,
              _pageSize3 = _store$getState$enrol4.pageSize,
              _filteredItems3 = _store$getState$enrol4.filteredItems,
              _filter = _store$getState$enrol4.filter;
            store.dispatch(paginateItemsToBeCorrected(_filteredItems3, 1, _pageSize3, action.payload, _filter));
            next(setCorrectionScreenCurrentPage(1));
            next(correctionScreenClearSelection());
            break;
          }
        case ENROLLMENT_FILES_ITEM_CORRECTION_SET_PAGE_SIZE:
          {
            var _store$getState$enrol5 = store.getState().enrollmentFiles.itemCorrection,
              _searchTerm3 = _store$getState$enrol5.searchTerm,
              _filteredItems4 = _store$getState$enrol5.filteredItems,
              _filter2 = _store$getState$enrol5.filter;
            store.dispatch(paginateItemsToBeCorrected(_filteredItems4, 1, action.payload, _searchTerm3, _filter2));
            next(setCorrectionScreenCurrentPage(1));
            break;
          }
        case ENROLLMENT_FILES_ITEM_CORRETION_REMOVE_SELECTED_ITEMS:
          {
            var _store$getState$enrol6 = store.getState().enrollmentFiles.itemCorrection,
              currentPage = _store$getState$enrol6.currentPage,
              _pageSize4 = _store$getState$enrol6.pageSize,
              _searchTerm4 = _store$getState$enrol6.searchTerm,
              _itemsToBeCorrected = _store$getState$enrol6.itemsToBeCorrected,
              _filteredItems5 = _store$getState$enrol6.filteredItems,
              selectedItems = _store$getState$enrol6.selectedItems,
              uploadTypeId = _store$getState$enrol6.uploadTypeId;
            var selectedItemsIds = selectedItems.map(function (si) {
                return si.id;
              }),
              remainingItems = _itemsToBeCorrected.filter(function (item) {
                return !selectedItemsIds.includes(item.id);
              }),
              remainingFilteredItems = _filteredItems5.filter(function (item) {
                return !selectedItemsIds.includes(item.id);
              });
            next(setItemsToBeCorrected(remainingItems, uploadTypeId));
            next(setFilteredItems(remainingFilteredItems));
            next(correctionScreenClearSelection());
            store.dispatch(paginateItemsToBeCorrected(remainingFilteredItems, currentPage, _pageSize4, _searchTerm4));
            break;
          }
        case ENROLLMENT_FILES_ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_FAILURE:
          {
            next(toggleModalVisibility(false));
            next(setValidationStatus(false));
            next(setSendCorrectedItemsInProgress(false));
            if (action.payload.items) {
              store.dispatch(setItemsToBeCorrected(action.payload.items, action.payload.uploadTypeId));
            } else {
              store.dispatch(showToast({
                type: 'error',
                text: errorCodes.genericErrorMessage
              }));
            }
            break;
          }
        case ENROLLMENT_FILES_ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_SUCCESS:
          {
            next(toggleModalVisibility(false));
            store.dispatch(setSendCorrectedItemsInProgress(false));
            store.dispatch(setCorrectionScreenSearchTerm(''));
            store.dispatch(setValidationStatus(true));
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
export default enrollmentFilesItemCorrection;