export var BRANCH_FIND_REQUEST = 'BRANCH_FIND_REQUEST';
export var BRANCH_FIND_FAILURE = 'BRANCH_FIND_FAILURE';
export var BRANCH_FIND_SUCCESS = 'BRANCH_FIND_SUCCESS';
export var ADD_USER_TO_BRANCH_REQUEST = 'ADD_USER_TO_BRANCH_REQUEST';
export var ADD_USER_TO_BRANCH_FAILURE = 'ADD_USER_TO_BRANCH_FAILURE';
export var ADD_USER_TO_BRANCH_SUCCESS = 'ADD_USER_TO_BRANCH_SUCCESS';
export var REMOVE_USER_FROM_BRANCH_REQUEST = 'REMOVE_USER_FROM_BRANCH_REQUEST';
export var REMOVE_USER_FROM_BRANCH_FAILURE = 'REMOVE_USER_FROM_BRANCH_FAILURE';
export var REMOVE_USER_FROM_BRANCH_SUCCESS = 'REMOVE_USER_FROM_BRANCH_SUCCESS';
export var BRANCH_UPSERT_REQUEST = 'BRANCH_UPSERT_REQUEST';
export var BRANCH_UPSERT_FAILURE = 'BRANCH_UPSERT_FAILURE';
export var BRANCH_UPSERT_SUCCESS = 'BRANCH_UPSERT_SUCCESS';
export var HIDE_MODAL = 'HIDE_MODAL';
export var SHOW_MODAL = 'SHOW_MODAL';
export var SHOW_MODAL_FOR_EDIT = 'SHOW_MODAL_FOR_EDIT';
export var SET_CURRENT_BRANCH = 'SET_CURRENT_BRANCH';
export var BLOCK_CURRENT_BRANCH_SELECTION = 'BLOCK_CURRENT_BRANCH_SELECTION';
export var SET_BRANCHES = 'SET_BRANCHES';
export var SET_UPSERT_BRANCH_RESULT = 'SET_UPSERT_BRANCH_RESULT';
export var SET_CURRENT_BRANCH_USER_IDS = 'SET_CURRENT_BRANCH_USER_IDS';
export var SET_BRANCHES_LOADED_FLAG = 'SET_BRANCHES_LOADED_FLAG';
export var BRANCH_DISABLE_REQUEST = 'BRANCH_DISABLE_REQUEST';
export var BRANCH_DISABLE_FAILURE = 'BRANCH_DISABLE_FAILURE';
export var BRANCH_DISABLE_SUCCESS = 'BRANCH_DISABLE_SUCCESS';
export var BRANCH_ENABLE_REQUEST = 'BRANCH_ENABLE_REQUEST';
export var BRANCH_ENABLE_FAILURE = 'BRANCH_ENABLE_FAILURE';
export var BRANCH_ENABLE_SUCCESS = 'BRANCH_ENABLE_SUCCESS';
export var AFTER_CURRENT_BRANCH_WAS_SET = 'AFTER_CURRENT_BRANCH_WAS_SET';
export var REMOVE_USER_FROM_BRANCHES_EXCEPT = 'REMOVE_USER_FROM_BRANCHES_EXCEPT';
export var findBranches = function findBranches() {
  return {
    type: BRANCH_FIND_REQUEST
  };
};
export var findBranchesFailure = function findBranchesFailure(errorMessage) {
  return {
    type: BRANCH_FIND_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var findBranchesSuccess = function findBranchesSuccess(branches) {
  return {
    type: BRANCH_FIND_SUCCESS,
    payload: branches
  };
};
export var addUserToBranch = function addUserToBranch(userId, branchId) {
  return {
    type: ADD_USER_TO_BRANCH_REQUEST,
    payload: {
      userId: userId,
      branchId: branchId
    }
  };
};
export var addUserToBranchFailure = function addUserToBranchFailure(errorMessage) {
  return {
    type: ADD_USER_TO_BRANCH_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var addUserToBranchSuccess = function addUserToBranchSuccess(userId, branchId) {
  return {
    type: ADD_USER_TO_BRANCH_SUCCESS,
    payload: {
      userId: userId,
      branchId: branchId
    }
  };
};
export var removeUserFromBranch = function removeUserFromBranch(userId, branchId) {
  return {
    type: REMOVE_USER_FROM_BRANCH_REQUEST,
    payload: {
      userId: userId,
      branchId: branchId
    }
  };
};
export var removeUserFromBranchFailure = function removeUserFromBranchFailure(errorMessage) {
  return {
    type: REMOVE_USER_FROM_BRANCH_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var removeUserFromBranchSuccess = function removeUserFromBranchSuccess(userId, branchId) {
  return {
    type: REMOVE_USER_FROM_BRANCH_SUCCESS,
    payload: {
      userId: userId,
      branchId: branchId
    }
  };
};
export var upsertBranch = function upsertBranch(branch) {
  return {
    type: BRANCH_UPSERT_REQUEST,
    payload: branch
  };
};
export var upsertBranchFailure = function upsertBranchFailure(errorMessage) {
  return {
    type: BRANCH_UPSERT_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var upsertBranchSuccess = function upsertBranchSuccess(branch) {
  return {
    type: BRANCH_UPSERT_SUCCESS,
    payload: branch
  };
};
export var hideModal = function hideModal() {
  return {
    type: HIDE_MODAL,
    payload: false
  };
};
export var showModal = function showModal() {
  return {
    type: SHOW_MODAL,
    payload: true
  };
};
export var showModalForEdit = function showModalForEdit(branchId) {
  return {
    type: SHOW_MODAL_FOR_EDIT,
    payload: branchId
  };
};
export var setCurrentBranch = function setCurrentBranch(branch) {
  return {
    type: SET_CURRENT_BRANCH,
    payload: branch
  };
};
export var setCurrentBranchUserIds = function setCurrentBranchUserIds(userIds) {
  return {
    type: SET_CURRENT_BRANCH_USER_IDS,
    payload: userIds
  };
};
export var afterCurrentBranchWasSet = function afterCurrentBranchWasSet(branch) {
  return {
    type: AFTER_CURRENT_BRANCH_WAS_SET,
    payload: branch
  };
};
export var blockCurrentBranchSelection = function blockCurrentBranchSelection(shouldBlock) {
  return {
    type: BLOCK_CURRENT_BRANCH_SELECTION,
    payload: shouldBlock
  };
};
export var setBranches = function setBranches(branches) {
  return {
    type: SET_BRANCHES,
    payload: branches
  };
};
export var setUpsertBranchResult = function setUpsertBranchResult(isUpsertBranchError, upsertBranchErrorMessage) {
  return {
    type: SET_UPSERT_BRANCH_RESULT,
    payload: {
      isUpsertBranchError: isUpsertBranchError,
      upsertBranchErrorMessage: upsertBranchErrorMessage
    }
  };
};
export var setBranchesLoadedFlag = function setBranchesLoadedFlag(loaded) {
  return {
    type: SET_BRANCHES_LOADED_FLAG,
    payload: loaded
  };
};
export var disableBranch = function disableBranch(branchId) {
  return {
    type: BRANCH_DISABLE_REQUEST,
    payload: branchId
  };
};
export var disableBranchFailure = function disableBranchFailure(errorMessage) {
  return {
    type: BRANCH_DISABLE_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var disableBranchSuccess = function disableBranchSuccess(status, text) {
  return {
    type: BRANCH_DISABLE_SUCCESS,
    payload: {
      status: status,
      text: text
    }
  };
};
export var enableBranch = function enableBranch(data) {
  return {
    type: BRANCH_ENABLE_REQUEST,
    payload: data
  };
};
export var enableBranchFailure = function enableBranchFailure(errorMessage) {
  return {
    type: BRANCH_ENABLE_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var enableBranchSuccess = function enableBranchSuccess() {
  return {
    type: BRANCH_ENABLE_SUCCESS
  };
};
export var removeUserFromBranchesExcept = function removeUserFromBranchesExcept(userId, branchId) {
  return {
    type: REMOVE_USER_FROM_BRANCHES_EXCEPT,
    payload: {
      userId: userId,
      branchId: branchId
    }
  };
};