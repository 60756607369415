import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { USER_SAVE_INFORMATION, USER_SET_LOGIN_ERROR, USER_SET_OTP_CAPTCHA, USER_SET_PASSWORD_CAPTCHA, USER_SET_PHONE_NUMBER_CAPTCHA, USER_SET_SMS_CODE, USER_SET_USERNAME_CAPTCHA } from 'actions/user';
export default function user(state, action) {
  state = state || {};
  switch (action.type) {
    case USER_SAVE_INFORMATION:
      return _objectSpread(_objectSpread({}, state), action.payload);
    case USER_SET_LOGIN_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        loginError: action.payload
      });
    case USER_SET_SMS_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        smsToken: action.payload.smsToken
      });
    case USER_SET_USERNAME_CAPTCHA:
      return _objectSpread(_objectSpread({}, state), {}, {
        usernameCaptcha: action.payload.captcha,
        loginError: action.payload.error
      });
    case USER_SET_OTP_CAPTCHA:
      return _objectSpread(_objectSpread({}, state), {}, {
        otpCaptcha: action.payload.captcha,
        loginError: action.payload.error
      });
    case USER_SET_PASSWORD_CAPTCHA:
      return _objectSpread(_objectSpread({}, state), {}, {
        passwordCaptcha: action.payload.captcha,
        loginError: action.payload.error
      });
    case USER_SET_PHONE_NUMBER_CAPTCHA:
      return _objectSpread(_objectSpread({}, state), {}, {
        phoneNumberCaptcha: action.payload.captcha,
        loginError: action.payload.error
      });
    default:
      return state;
  }
}