import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { addCardToSelection, clearSelection, deleteCard, findCardsRequest, hideCardMigrationModal, hideCardReissueModal, hideChangeTicketValueModal, hideConfirmationModalForCardReissue, hideModalForCardConfirmation, hidePinReissueModal, reissueCards, removeCardFromSelection, selectAllCards, setCardsCurrentPage, setCardsLoadedFlag, setCardsPageSize, setSelectedCards, showCardMigrationModal, showCardReissueModal, showChangeTicketValueModal, showConfirmatiomModalForCardReissue, showModalForCardConfirmation, showPinReissueModal } from 'actions/card';
import { bindActionCreators } from 'redux';
import { branchStatus } from 'utils/enums';
import CardsGrid from './CardsGrid';
import { connect } from 'react-redux';
import { showLoadingSpinner } from 'actions/loadingSpinner';
var mapStateToProps = function mapStateToProps(state) {
  return _objectSpread(_objectSpread(_objectSpread({}, state.card), state.branch), {}, {
    roleIds: state.user.roleIds,
    branchesCount: state.branch.branches ? state.branch.branches.filter(function (b) {
      return b.status === branchStatus.enabled;
    }).length : null
  });
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addCardToSelection: addCardToSelection,
    clearSelection: clearSelection,
    deleteCard: deleteCard,
    removeCardFromSelection: removeCardFromSelection,
    selectAllCards: selectAllCards,
    setCardsCurrentPage: setCardsCurrentPage,
    setCardsPageSize: setCardsPageSize,
    setSelectedCards: setSelectedCards,
    showCardReissueModal: showCardReissueModal,
    hideCardReissueModal: hideCardReissueModal,
    showPinReissueModal: showPinReissueModal,
    hidePinReissueModal: hidePinReissueModal,
    showCardMigrationModal: showCardMigrationModal,
    hideCardMigrationModal: hideCardMigrationModal,
    showChangeTicketValueModal: showChangeTicketValueModal,
    hideChangeTicketValueModal: hideChangeTicketValueModal,
    showModalForCardConfirmation: showModalForCardConfirmation,
    hideModalForCardConfirmation: hideModalForCardConfirmation,
    showConfirmatiomModalForCardReissue: showConfirmatiomModalForCardReissue,
    hideConfirmationModalForCardReissue: hideConfirmationModalForCardReissue,
    findCardsRequest: findCardsRequest,
    showLoadingSpinner: showLoadingSpinner,
    reissueCards: reissueCards,
    setCardsLoadedFlag: setCardsLoadedFlag
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(CardsGrid);