export var MANAGER_FIND_REQUEST = 'MANAGER_FIND_REQUEST';
export var MANAGER_FIND_FAILURE = 'MANAGER_FIND_FAILURE';
export var MANAGER_FIND_SUCCESS = 'MANAGER_FIND_SUCCESS';
export var MANAGER_CREATE_REQUEST = 'MANAGER_CREATE_REQUEST';
export var MANAGER_CREATE_FAILURE = 'MANAGER_CREATE_FAILURE';
export var MANAGER_CREATE_SUCCESS = 'MANAGER_CREATE_SUCCESS';
export var MANAGER_UPDATE_REQUEST = 'MANAGER_UPDATE_REQUEST';
export var MANAGER_UPDATE_FAILURE = 'MANAGER_UPDATE_FAILURE';
export var MANAGER_UPDATE_SUCCESS = 'MANAGER_UPDATE_SUCCESS';
export var MANAGER_DELETE_REQUEST = 'MANAGER_DELETE_REQUEST';
export var MANAGER_DELETE_SUCCESS = 'MANAGER_DELETE_SUCCESS';
export var MANAGER_DELETE_FAILURE = 'MANAGER_DELETE_FAILURE';
export var MANAGER_SET_SEARCH_TERM = 'MANAGER_SET_SEARCH_TERM';
export var MANAGER_SET_TOTAL_COUNT = 'MANAGER_SET_TOTAL_COUNT';
export var MANAGER_SET_PAGE_COUNT = 'MANAGER_SET_PAGE_COUNT';
export var MANAGER_SET_PAGE_SIZE = 'MANAGER_SET_PAGE_SIZE';
export var MANAGER_SET_CURRENT_PAGE = 'MANAGER_SET_CURRENT_PAGE';
export var SET_MANAGERS = 'SET_MANAGERS';
export var MANAGER_HIDE_MODAL = 'MANAGER_HIDE_MODAL';
export var MANAGER_SET_ERROR_MODAL = 'MANAGER_SET_ERROR_MODAL';
export var MANAGER_SHOW_MODAL = 'MANAGER_SHOW_MODAL';
export var SET_MANAGERS_LOADED_FLAG = 'SET_MANAGERS_LOADED_FLAG';
export var SHOW_UPDATE_MANAGER_MODAL = 'SHOW_UPDATE_MANAGER_MODAL';
export var SET_CURRENT_MANAGER = 'SET_CURRENT_MANAGER';
export var findManagersRequest = function findManagersRequest() {
  return {
    type: MANAGER_FIND_REQUEST
  };
};
export var findManagersFailure = function findManagersFailure(errorMessage) {
  return {
    type: MANAGER_FIND_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var findManagersSuccess = function findManagersSuccess(entities, totalCount) {
  return {
    type: MANAGER_FIND_SUCCESS,
    payload: {
      entities: entities,
      totalCount: totalCount
    }
  };
};
export var createManagerRequest = function createManagerRequest(data) {
  return {
    type: MANAGER_CREATE_REQUEST,
    payload: data
  };
};
export var createManagerFailure = function createManagerFailure(errorMessage) {
  return {
    type: MANAGER_CREATE_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var createManagerSuccess = function createManagerSuccess(user, showToast) {
  return {
    type: MANAGER_CREATE_SUCCESS,
    payload: {
      user: user,
      showToast: showToast
    }
  };
};
export var updateManagerRequest = function updateManagerRequest(user) {
  return {
    type: MANAGER_UPDATE_REQUEST,
    payload: user
  };
};
export var updateManagerFailure = function updateManagerFailure(errorMessage) {
  return {
    type: MANAGER_UPDATE_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var updateManagerSuccess = function updateManagerSuccess(user, showToast) {
  return {
    type: MANAGER_UPDATE_SUCCESS,
    payload: {
      user: user,
      showToast: showToast
    }
  };
};
export var deleteManagerRequest = function deleteManagerRequest(user) {
  return {
    type: MANAGER_DELETE_REQUEST,
    payload: user
  };
};
export var deleteManagerFailure = function deleteManagerFailure(errorMessage) {
  return {
    type: MANAGER_DELETE_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var deleteManagerSuccess = function deleteManagerSuccess(userFullName, userId) {
  return {
    type: MANAGER_DELETE_SUCCESS,
    payload: {
      userFullName: userFullName,
      userId: userId
    }
  };
};
export var setManagers = function setManagers(managers) {
  return {
    type: SET_MANAGERS,
    payload: managers
  };
};
export var setManagersSearchTerm = function setManagersSearchTerm(searchTerm) {
  return {
    type: MANAGER_SET_SEARCH_TERM,
    payload: searchTerm
  };
};
export var setManagersTotalCount = function setManagersTotalCount(totalCount) {
  return {
    type: MANAGER_SET_TOTAL_COUNT,
    payload: totalCount
  };
};
export var setManagersPageCount = function setManagersPageCount(pageCount) {
  return {
    type: MANAGER_SET_PAGE_COUNT,
    payload: pageCount
  };
};
export var setManagersPageSize = function setManagersPageSize(pageSize) {
  return {
    type: MANAGER_SET_PAGE_SIZE,
    payload: pageSize
  };
};
export var setManagersCurrentPage = function setManagersCurrentPage(currentPage) {
  return {
    type: MANAGER_SET_CURRENT_PAGE,
    payload: currentPage
  };
};
export var managersHideModal = function managersHideModal() {
  return {
    type: MANAGER_HIDE_MODAL
  };
};
export var managersSetModalError = function managersSetModalError(error) {
  return {
    type: MANAGER_SET_ERROR_MODAL,
    payload: error
  };
};
export var managersShowModal = function managersShowModal() {
  return {
    type: MANAGER_SHOW_MODAL
  };
};
export var setManagersLoadedFlag = function setManagersLoadedFlag(loaded) {
  return {
    type: SET_MANAGERS_LOADED_FLAG,
    payload: loaded
  };
};
export var showUpdateManagerModal = function showUpdateManagerModal(user) {
  return {
    type: SHOW_UPDATE_MANAGER_MODAL,
    payload: user
  };
};
export var setCurrentManager = function setCurrentManager(user) {
  return {
    type: SET_CURRENT_MANAGER,
    payload: user
  };
};