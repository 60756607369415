import { downloadEnrollmentFileEntries, fetchEnrollmentFileDetails } from './enrollmentFileEntryAPI';
import { createSlice } from '@reduxjs/toolkit';
export var enrollmentFileEntry = createSlice({
  name: 'enrollmentFileEntry',
  initialState: {
    enrollmentFileEntries: [],
    loading: true,
    downloadLoading: false
  },
  reducers: {},
  extraReducers: function extraReducers(builder) {
    builder.addCase(fetchEnrollmentFileDetails.pending, function (state) {
      state.loading = true;
    });
    builder.addCase(fetchEnrollmentFileDetails.fulfilled, function (state, action) {
      state.loading = false;
      state.enrollmentFileEntries = action.payload;
    });
    builder.addCase(downloadEnrollmentFileEntries.pending, function (state) {
      state.downloadLoading = true;
    });
    builder.addCase(downloadEnrollmentFileEntries.fulfilled, function (state) {
      state.downloadLoading = false;
    });
  }
});
var _enrollmentFileEntry$ = enrollmentFileEntry.actions,
  setSelectedEntries = _enrollmentFileEntry$.setSelectedEntries,
  setSelectedDetails = _enrollmentFileEntry$.setSelectedDetails;
export { setSelectedEntries, setSelectedDetails };
export default enrollmentFileEntry.reducer;