import { correctionScreenClearSelection, sendCorrectedItemsRequest, setCorrectionScreenSearchTerm, setSendCorrectedItemsInProgress } from 'actions/enrollmentFilesItemCorrection';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DefaultItemCorrectionHeader from '../../../DefaultItemCorrection/Header/DefaultItemCorrectionHeader';
var mapStateToProps = function mapStateToProps(state) {
  return {
    itemsToBeCorrected: state.enrollmentFiles.itemCorrection.itemsToBeCorrected,
    items: state.enrollmentFiles.itemCorrection.fileEntries,
    sendCorrectedItemsInProgress: state.enrollmentFiles.itemCorrection.sendCorrectedItemsInProgress
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    correctionScreenClearSelection: correctionScreenClearSelection,
    sendCorrectedItemsRequest: sendCorrectedItemsRequest,
    setCorrectionScreenSearchTerm: setCorrectionScreenSearchTerm,
    setSendCorrectedItemsInProgress: setSendCorrectedItemsInProgress
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(DefaultItemCorrectionHeader);