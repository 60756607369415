import moment from 'moment';
export default {
  getFormattedToday: function getFormattedToday() {
    var today = new Date();
    var yyyy = today.getFullYear();
    var mm = today.getMonth() + 1;
    var dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return yyyy + '-' + mm + '-' + dd;
  },
  getFormattedTodayMinusOneYear: function getFormattedTodayMinusOneYear() {
    var today = new Date();
    var yyyy = today.getFullYear() - 1;
    var mm = today.getMonth() + 1;
    var dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return yyyy + '-' + mm + '-' + dd;
  },
  formatDate: function formatDate(inputDateString) {
    return moment(inputDateString).format('DD MMM YYYY');
  },
  formatStartDate: function formatStartDate(startDate) {
    var formatPattern = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD hh:mm A';
    return moment(startDate).startOf('day').format(formatPattern);
  },
  formatEndDate: function formatEndDate(endDate) {
    var formatPattern = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD hh:mm A';
    return moment(endDate).endOf('day').format(formatPattern);
  },
  minDate: function minDate() {
    return new Date(0).toDateString();
  }
};