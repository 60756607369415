import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { errorFilter, selectionType } from 'utils/enums';
import { ITEM_CORRECTION_ADD_ITEM_TO_SELECTION, ITEM_CORRECTION_CLEAR_SELECTION, ITEM_CORRECTION_CORRECT_ITEM, ITEM_CORRECTION_REMOVE_ITEM_FROM_SELECTION, ITEM_CORRECTION_SELECT_ALL, ITEM_CORRECTION_SET_CURRENT_PAGE, ITEM_CORRECTION_SET_ERROR_FILTER, ITEM_CORRECTION_SET_FILTERED_ITEMS, ITEM_CORRECTION_SET_IS_CAPTCHA_VALID, ITEM_CORRECTION_SET_ITEMS, ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED, ITEM_CORRECTION_SET_PAGE_COUNT, ITEM_CORRECTION_SET_PAGE_SIZE, ITEM_CORRECTION_SET_SEARCH_TERM, ITEM_CORRECTION_SET_SEND_CORRECTED_ITEMS_IN_PROGRESS, ITEM_CORRECTION_SET_TOTAL_COUNT } from 'actions/itemCorrection';
export default function itemCorrection(state, action) {
  state = state || {
    cards: null,
    filter: errorFilter.all,
    selectState: selectionType.none,
    selectedItems: [],
    unselectedItems: [],
    filteredItems: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    searchTerm: null
  };
  switch (action.type) {
    case ITEM_CORRECTION_SET_ITEMS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cards: action.payload
        });
      }
    case ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          itemsToBeCorrected: action.payload.items,
          uploadTypeId: action.payload.uploadTypeId
        });
      }
    case ITEM_CORRECTION_ADD_ITEM_TO_SELECTION:
      {
        var updatedSelectState;
        if (state.selectState !== selectionType.all && state.selectedItems.length + 1 === state.totalCount) {
          updatedSelectState = selectionType.all;
        } else if (state.selectState === selectionType.none) {
          updatedSelectState = selectionType.multiple;
        } else {
          updatedSelectState = state.selectState;
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedItems: [].concat(_toConsumableArray(state.selectedItems), [action.payload]),
          unselectedItems: state.selectState === selectionType.all ? state.unselectedItems.filter(function (itemId) {
            return itemId != action.payload;
          }) : [],
          selectState: updatedSelectState
        });
      }
    case ITEM_CORRECTION_REMOVE_ITEM_FROM_SELECTION:
      {
        var _updatedSelectState;
        var isSelectStateDifferentThanMultiple = state.selectState !== selectionType.multiple;
        var selectedItemsCount = state.selectedItems.length;
        if (isSelectStateDifferentThanMultiple && selectedItemsCount > 1) {
          _updatedSelectState = selectionType.multiple;
        } else if (isSelectStateDifferentThanMultiple && selectedItemsCount <= 1) {
          _updatedSelectState = selectionType.none;
        } else if (!isSelectStateDifferentThanMultiple && selectedItemsCount > 1) {
          _updatedSelectState = state.selectState;
        } else {
          _updatedSelectState = selectionType.none;
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedItems: state.selectedItems.filter(function (item) {
            return item.cardId != action.payload.cardId;
          }),
          unselectedItems: state.selectState === selectionType.all ? _toConsumableArray(new Set([].concat(_toConsumableArray(state.unselectedItems), [action.payload]))) : [],
          selectState: _updatedSelectState
        });
      }
    case ITEM_CORRECTION_SELECT_ALL:
      {
        var selectedItems = state.filteredItems,
          searchTerm = state.searchTerm;
        selectedItems = searchTerm && searchTerm !== '' ? selectedItems.filter(function (item) {
          return item.lastName.toLowerCase().startsWith(searchTerm.toLowerCase()) || item.cnp.startsWith(searchTerm.toLowerCase());
        }) : selectedItems;
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedItems: selectedItems.map(function (item) {
            return {
              cardId: item.id
            };
          }),
          unselectedItems: [],
          selectState: selectionType.all
        });
      }
    case ITEM_CORRECTION_CLEAR_SELECTION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedItems: [],
          unselectedItems: [],
          selectState: selectionType.none
        });
      }
    case ITEM_CORRECTION_SET_CURRENT_PAGE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          currentPage: action.payload
        });
      }
    case ITEM_CORRECTION_SET_PAGE_COUNT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          pageCount: action.payload
        });
      }
    case ITEM_CORRECTION_SET_SEARCH_TERM:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchTerm: action.payload
        });
      }
    case ITEM_CORRECTION_SET_PAGE_SIZE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          pageSize: action.payload
        });
      }
    case ITEM_CORRECTION_SET_TOTAL_COUNT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          totalCount: action.payload
        });
      }
    case ITEM_CORRECTION_SET_ERROR_FILTER:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          filter: action.payload
        });
      }
    case ITEM_CORRECTION_SET_FILTERED_ITEMS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          filteredItems: action.payload
        });
      }
    case ITEM_CORRECTION_CORRECT_ITEM:
      {
        var items = state.itemsToBeCorrected,
          itemIndex = items.findIndex(function (item) {
            return item.id === action.payload.id;
          });
        items.splice(itemIndex, 1, action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          itemsToBeCorrected: _toConsumableArray(items)
        });
      }
    case ITEM_CORRECTION_SET_SEND_CORRECTED_ITEMS_IN_PROGRESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          sendCorrectedItemsInProgress: action.payload
        });
      }
    case ITEM_CORRECTION_SET_IS_CAPTCHA_VALID:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isCaptchaValid: action.payload
        });
      }
    default:
      {
        return state;
      }
  }
}