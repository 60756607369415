import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ENROLLMENT_FILES_ITEM_CORRECTION_ADD_ITEM_TO_SELECTION, ENROLLMENT_FILES_ITEM_CORRECTION_CLEAR_SELECTION, ENROLLMENT_FILES_ITEM_CORRECTION_CORRECT_ITEM, ENROLLMENT_FILES_ITEM_CORRECTION_REMOVE_ITEM_FROM_SELECTION, ENROLLMENT_FILES_ITEM_CORRECTION_RESET_STATE, ENROLLMENT_FILES_ITEM_CORRECTION_SELECT_ALL, ENROLLMENT_FILES_ITEM_CORRECTION_SET_CURRENT_PAGE, ENROLLMENT_FILES_ITEM_CORRECTION_SET_ERROR_FILTER, ENROLLMENT_FILES_ITEM_CORRECTION_SET_FILE_NAME, ENROLLMENT_FILES_ITEM_CORRECTION_SET_FILTERED_ITEMS, ENROLLMENT_FILES_ITEM_CORRECTION_SET_IS_CAPTCHA_VALID, ENROLLMENT_FILES_ITEM_CORRECTION_SET_ITEMS, ENROLLMENT_FILES_ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED, ENROLLMENT_FILES_ITEM_CORRECTION_SET_PAGE_COUNT, ENROLLMENT_FILES_ITEM_CORRECTION_SET_PAGE_SIZE, ENROLLMENT_FILES_ITEM_CORRECTION_SET_SEARCH_TERM, ENROLLMENT_FILES_ITEM_CORRECTION_SET_SEND_CORRECTED_ITEMS_IN_PROGRESS, ENROLLMENT_FILES_ITEM_CORRECTION_SET_TOTAL_COUNT } from 'actions/enrollmentFilesItemCorrection';
import { SET_CURRENT_PAGE, SET_ENROLLMENT_FILE_STATUSES, SET_ENROLLMENT_FILES, SET_ENROLLMENT_FILES_FILTERS, SET_ENROLLMENT_FILES_LOADED_FLAG, SET_PAGE_COUNT, SET_PAGE_SIZE, SET_SELECTED_FILE, SET_TOTAL_COUNT } from 'actions/enrollmentFiles';
import datetime from 'utils/datetime';
import enrollmentFilesItemCorrection from './enrollmentFilesItemCorrection';
import { enrollmentFileStatus } from '../utils/enums';
export default function enrollmentFiles(state, action) {
  state = state || {
    enrollmentFiles: null,
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    itemCorrection: enrollmentFilesItemCorrection(null, action)
  };
  switch (action.type) {
    case SET_ENROLLMENT_FILES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          enrollmentFiles: action.payload.map(function (enrollmentFile) {
            return Object.assign({}, enrollmentFile, {
              statusName: enrollmentFile.enrollmentFileStatus.id == enrollmentFileStatus.AWAITS_SUPERVISION ? 'Trimis spre procesare' : enrollmentFile.enrollmentFileStatus.name,
              statusId: enrollmentFile.enrollmentFileStatus.id,
              createdDate: datetime.formatDate(enrollmentFile.createdDate),
              emittedCards: enrollmentFile.issuedCardsCount
            });
          })
        });
      }
    case SET_CURRENT_PAGE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          currentPage: action.payload
        });
      }
    case SET_PAGE_COUNT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          pageCount: action.payload
        });
      }
    case SET_PAGE_SIZE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          pageSize: action.payload
        });
      }
    case SET_TOTAL_COUNT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          totalCount: action.payload
        });
      }
    case SET_ENROLLMENT_FILES_LOADED_FLAG:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          enrollmentFilesLoaded: action.payload
        });
      }
    case ENROLLMENT_FILES_ITEM_CORRECTION_RESET_STATE:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SET_FILE_NAME:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SET_ITEMS:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED:
    case ENROLLMENT_FILES_ITEM_CORRECTION_ADD_ITEM_TO_SELECTION:
    case ENROLLMENT_FILES_ITEM_CORRECTION_REMOVE_ITEM_FROM_SELECTION:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SELECT_ALL:
    case ENROLLMENT_FILES_ITEM_CORRECTION_CLEAR_SELECTION:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SET_CURRENT_PAGE:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SET_PAGE_COUNT:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SET_SEARCH_TERM:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SET_PAGE_SIZE:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SET_TOTAL_COUNT:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SET_ERROR_FILTER:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SET_FILTERED_ITEMS:
    case ENROLLMENT_FILES_ITEM_CORRECTION_CORRECT_ITEM:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SET_SEND_CORRECTED_ITEMS_IN_PROGRESS:
    case ENROLLMENT_FILES_ITEM_CORRECTION_SET_IS_CAPTCHA_VALID:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          itemCorrection: enrollmentFilesItemCorrection(state.itemCorrection, action)
        });
      }
    case SET_ENROLLMENT_FILES_FILTERS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          filters: _objectSpread(_objectSpread({}, state.filters), action.payload)
        });
      }
    case SET_ENROLLMENT_FILE_STATUSES:
      {
        var allStatusOption = {
          id: null,
          name: 'Toate statusurile'
        };
        var possibleStatuses = [allStatusOption];
        possibleStatuses.push.apply(possibleStatuses, _toConsumableArray(action.payload.map(function (status) {
          return Object.assign({}, status, {
            id: status.id,
            name: status.id == enrollmentFileStatus.AWAITS_SUPERVISION ? 'Trimis spre procesare' : status.name
          });
        })));
        return _objectSpread(_objectSpread({}, state), {}, {
          enrollmentFileStatuses: possibleStatuses
        });
      }
    case SET_SELECTED_FILE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedFile: action.payload
        });
      }
    default:
      {
        return state;
      }
  }
}