import { ORDER_FIND_FAILURE, ORDER_FIND_SUCCESS, setOrders, setOrdersLoadedFlag } from 'actions/order';
function order() {
  return function (next) {
    return function (action) {
      switch (action.type) {
        case ORDER_FIND_SUCCESS:
          {
            var orders = action.payload.orders;
            var updatedOrders = orders || [];
            updatedOrders.splice(0, 0, {
              id: null,
              creationDate: 'Toate comenzile'
            });
            next(setOrders(updatedOrders));
            next(setOrdersLoadedFlag(true));
            break;
          }
        case ORDER_FIND_FAILURE:
          {
            next(setOrders([]));
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
export default order;