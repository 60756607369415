export var UPLOAD_CSV_FILE_REQUEST = 'UPLOAD_CSV_FILE_REQUEST';
export var UPLOAD_CSV_FILE_FAILURE = 'UPLOAD_CSV_FILE_FAILURE';
export var UPLOAD_CSV_FILE_SUCCESS = 'UPLOAD_CSV_FILE_SUCCESS';
export var UPLOAD_SET_UPLOAD_ERROR = 'UPLOAD_SET_UPLOAD_ERROR';
export var SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN';
export var UPLOAD_SET_VALIDATION_STATUS = 'UPLOAD_SET_VALIDATION_STATUS';
export var SET_CANCELLED_UPLOAD_FLAG = 'SET_CANCELLED_UPLOAD_FLAG';
export var UPLOAD_SET_FILE_NAME = 'UPLOAD_SET_FILE_NAME';
export var UPLOAD_SET_CONFIG = 'UPLOAD_SET_CONFIG';
export var uploadCsvFileRequest = function uploadCsvFileRequest(csvFile, requestId) {
  return {
    type: UPLOAD_CSV_FILE_REQUEST,
    payload: {
      csvFile: csvFile,
      requestId: requestId
    }
  };
};
export var uploadCsvFileFailure = function uploadCsvFileFailure(errorMessage) {
  return {
    type: UPLOAD_CSV_FILE_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var uploadCsvFileSuccess = function uploadCsvFileSuccess(fileUploadResults, uploadTypeId) {
  return {
    type: UPLOAD_CSV_FILE_SUCCESS,
    payload: {
      fileUploadResults: fileUploadResults,
      uploadTypeId: uploadTypeId
    }
  };
};
export var setUploadError = function setUploadError(errorType) {
  return {
    type: UPLOAD_SET_UPLOAD_ERROR,
    payload: errorType
  };
};
export var setValidationStatus = function setValidationStatus(validationStatus) {
  return {
    type: UPLOAD_SET_VALIDATION_STATUS,
    payload: validationStatus
  };
};
export var setFileName = function setFileName(fileName) {
  return {
    type: UPLOAD_SET_FILE_NAME,
    payload: fileName
  };
};
export var showLoadingScreen = function showLoadingScreen(show) {
  return {
    type: SHOW_LOADING_SCREEN,
    payload: show
  };
};
export var setCancelledUploadFlag = function setCancelledUploadFlag(uploadCancelled, requestId) {
  return {
    type: SET_CANCELLED_UPLOAD_FLAG,
    payload: {
      uploadCancelled: uploadCancelled,
      requestId: requestId
    }
  };
};
export var setUploadConfig = function setUploadConfig(config) {
  return {
    type: UPLOAD_SET_CONFIG,
    payload: config
  };
};