export var LOADING_SPINNER_SHOW = 'LOADING_SPINNER_SHOW';
export var LOADING_SPINNER_HIDE = 'LOADING_SPINNER_HIDE';
export var showLoadingSpinner = function showLoadingSpinner() {
  return {
    type: LOADING_SPINNER_SHOW
  };
};
export var hideLoadingSpinner = function hideLoadingSpinner() {
  return {
    type: LOADING_SPINNER_HIDE
  };
};