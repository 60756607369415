import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ADD_USER_TO_BRANCH_FAILURE, BLOCK_CURRENT_BRANCH_SELECTION, HIDE_MODAL, REMOVE_USER_FROM_BRANCH_FAILURE, SET_BRANCHES, SET_BRANCHES_LOADED_FLAG, SET_CURRENT_BRANCH, SET_CURRENT_BRANCH_USER_IDS, SET_UPSERT_BRANCH_RESULT, SHOW_MODAL, SHOW_MODAL_FOR_EDIT } from '../actions/branch';
export default function branch(state, action) {
  state = state || {
    branches: [],
    isModalVisibile: false,
    isUpsertBranchError: false,
    upsertBranchErrorMessage: '',
    isModalInEditMode: false
  };
  switch (action.type) {
    case SET_BRANCHES:
      return _objectSpread(_objectSpread({}, state), {}, {
        branches: action.payload
      });
    case SET_UPSERT_BRANCH_RESULT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUpsertBranchError: action.payload.isUpsertBranchError,
        upsertBranchErrorMessage: action.payload.upsertBranchErrorMessage
      });
    case HIDE_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isModalVisibile: action.payload,
        isUpsertBranchError: false,
        upsertBranchErrorMessage: '',
        cities: [],
        localities: []
      });
    case SHOW_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isModalVisibile: action.payload,
        isUpsertBranchError: false,
        upsertBranchErrorMessage: '',
        cities: [],
        localities: [],
        isModalInEditMode: false
      });
    case SET_CURRENT_BRANCH:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentBranch: action.payload
      });
    case SET_CURRENT_BRANCH_USER_IDS:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentBranch: _objectSpread(_objectSpread({}, state.currentBranch), {}, {
          userIds: action.payload
        })
      });
    case BLOCK_CURRENT_BRANCH_SELECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        blockCurrentBranchSelection: action.payload
      });
    case ADD_USER_TO_BRANCH_FAILURE:
    case REMOVE_USER_FROM_BRANCH_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        addRemoveUserToBranchErrorMessage: action.payload.errorMessage
      });
    case SHOW_MODAL_FOR_EDIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isModalVisibile: true,
        isUpsertBranchError: false,
        upsertBranchErrorMessage: '',
        cities: [],
        localities: [],
        isModalInEditMode: true
      });
    case SET_BRANCHES_LOADED_FLAG:
      return _objectSpread(_objectSpread({}, state), {}, {
        branchesLoaded: action.payload
      });
    default:
      return state;
  }
}