export var auditActions = [{
  value: null,
  name: 'Toate'
}, {
  value: 0,
  name: 'Login'
}, {
  value: 1,
  name: 'Parola uitata'
}, {
  value: 2,
  name: 'Schimbare parola'
}, {
  value: 3,
  name: 'Creare utilizator'
}, {
  value: 4,
  name: 'Modificare utilizator'
}, {
  value: 5,
  name: 'Stergere utilizator'
}, {
  value: 6,
  name: 'Alocare utilizator la punct de lucru'
}, {
  value: 7,
  name: 'Dealocare utilizator la punct de lucru'
}, {
  value: 8,
  name: 'Adaugare punct de lucru'
}, {
  value: 9,
  name: 'Modificare punct de lucru'
}, {
  value: 10,
  name: 'Dezactivare punct de lucru'
}, {
  value: 11,
  name: 'Comanda de carduri'
}, {
  value: 12,
  name: 'Stergere carduri'
}, {
  value: 13,
  name: 'Confirmare de predare carduri'
}, {
  value: 14,
  name: 'Reemitere carduri'
}, {
  value: 15,
  name: 'Reemitere cod PIN'
}, {
  value: 16,
  name: 'Modificare valoare tichet'
}, {
  value: 17,
  name: 'Migrare de carduri'
}, {
  value: 18,
  name: 'Reactivare punct de lucru'
}, {
  value: 19,
  name: 'Anulare factura'
}, {
  value: 20,
  name: 'Stornare factura'
}, {
  value: 21,
  name: 'Comanda carduri salarii'
}, {
  value: 22,
  name: 'Anulare fisier'
}];