import { correctionScreenAddItemToSelection, correctionScreenClearSelection, correctionScreenRemoveItemFromSelection, correctionScreenRemoveSelectedItems, correctionScreenSelectAllItems, correctItem, sendCorrectedItemsRequest, setIsCaptchaValid, setSendCorrectedItemsInProgress } from 'actions/enrollmentFilesItemCorrection';
import { toggleModalVisibility, toggleSubmitButton } from '../../../../actions/customModal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DefaultItemCorrectionBody from '../../../DefaultItemCorrection/Body/DefaultItemCorrectionBody';
var mapStateToProps = function mapStateToProps(state) {
  return {
    selectedItems: state.enrollmentFiles.itemCorrection.selectedItems,
    selectState: state.enrollmentFiles.itemCorrection.selectState,
    unselectedItems: state.enrollmentFiles.itemCorrection.unselectedItems,
    totalCount: state.enrollmentFiles.itemCorrection.totalCount,
    filter: state.enrollmentFiles.itemCorrection.filter,
    itemsToBeCorrected: state.enrollmentFiles.itemCorrection.itemsToBeCorrected,
    isCaptchaModalShowable: state.customModal.isModalShowable,
    isCaptchaValid: state.enrollmentFiles.itemCorrection.isCaptchaValid
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    correctionScreenAddItemToSelection: correctionScreenAddItemToSelection,
    correctionScreenClearSelection: correctionScreenClearSelection,
    correctionScreenRemoveItemFromSelection: correctionScreenRemoveItemFromSelection,
    correctionScreenRemoveSelectedItems: correctionScreenRemoveSelectedItems,
    correctionScreenSelectAllItems: correctionScreenSelectAllItems,
    correctItem: correctItem,
    toggleModalVisibility: toggleModalVisibility,
    setIsCaptchaValid: setIsCaptchaValid,
    toggleSubmitButton: toggleSubmitButton,
    sendCorrectedItemsRequest: sendCorrectedItemsRequest,
    setSendCorrectedItemsInProgress: setSendCorrectedItemsInProgress
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(DefaultItemCorrectionBody);