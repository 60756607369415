import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
export var convertFilterObjectToList = function convertFilterObjectToList(inputObject) {
  if (!inputObject) return [];
  var filtersList = [];
  for (var _i = 0, _Object$entries = Object.entries(inputObject); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
      key = _Object$entries$_i[0],
      value = _Object$entries$_i[1];
    filtersList.push({
      columnName: key,
      value: value.value,
      operator: value.operator || null
    });
  }
  return filtersList;
};