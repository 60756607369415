export var TOGGLE_SUBMIT_BUTTON = 'TOGGLE_SUBMIT_BUTTON';
export var TOGGLE_MODAL_VISIBILITY = 'TOGGLE_MODAL_VISIBILITY';
export var toggleSubmitButton = function toggleSubmitButton(value) {
  return {
    type: TOGGLE_SUBMIT_BUTTON,
    payload: value
  };
};
export var toggleModalVisibility = function toggleModalVisibility(value) {
  return {
    type: TOGGLE_MODAL_VISIBILITY,
    payload: value
  };
};