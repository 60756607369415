import { CANCEL_FILE_FAILURE, CANCEL_FILE_SUCCESS, FIND_ENROLLMENT_FILES_FAILURE, FIND_ENROLLMENT_FILES_SUCCESS, findEnrollmentFilesRequest, SET_PAGE_SIZE, setEnrollmentFiles, setEnrollmentFilesCurrentPage, setEnrollmentFilesFilters, setEnrollmentFilesLoadedFlag, setEnrollmentFilesPageCount, setEnrollmentFilesTotalCount } from 'actions/enrollmentFiles';
import { showToast } from 'actions/toast';
function enrollmentFiles(store) {
  return function (next) {
    return function (action) {
      switch (action.type) {
        case FIND_ENROLLMENT_FILES_SUCCESS:
          {
            var pageSize = store.getState().enrollmentFiles.pageSize,
              _action$payload = action.payload,
              _enrollmentFiles = _action$payload.enrollmentFiles,
              totalCount = _action$payload.totalCount;
            next(setEnrollmentFiles(_enrollmentFiles));
            next(setEnrollmentFilesPageCount(Math.ceil(totalCount / pageSize)));
            next(setEnrollmentFilesTotalCount(totalCount));
            next(setEnrollmentFilesLoadedFlag(true));
            break;
          }
        case FIND_ENROLLMENT_FILES_FAILURE:
          {
            next(setEnrollmentFiles([]));
            next(setEnrollmentFilesPageCount(1));
            next(setEnrollmentFilesTotalCount(0));
            next(setEnrollmentFilesLoadedFlag(true));
            break;
          }
        case SET_PAGE_SIZE:
          {
            next(setEnrollmentFilesCurrentPage(1));
            break;
          }
        case CANCEL_FILE_SUCCESS:
          {
            next(showToast({
              type: 'success',
              text: 'Fisierul a fost anulat cu succes'
            }));
            next(setEnrollmentFilesFilters({
              createdDate: {
                value: null
              },
              statusId: {
                value: null
              },
              createdBy: {
                value: ''
              }
            }));
            store.dispatch(findEnrollmentFilesRequest());
            break;
          }
        case CANCEL_FILE_FAILURE:
          {
            next(showToast({
              type: 'error',
              text: 'Fisierul nu a putut fi anulat.'
            }));
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
export default enrollmentFiles;