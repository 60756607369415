import { LOCATION_CHANGE, saveUserInformation, USER_CHANGE_PASSWORD_SUCCESS, USER_GENERATE_SMS_TOKEN_FAILURE, USER_SUBMIT_SMS_TOKEN_SUCCESS } from 'actions/user';
import history from 'utils/history';
import { isFirstLogin } from 'actions/user';
import routes from 'utils/routes';
import { showToast } from 'actions/toast';
function router(_ref) {
  var getState = _ref.getState,
    dispatch = _ref.dispatch;
  history.listen(function (location, action) {
    dispatch({
      type: LOCATION_CHANGE,
      payload: {
        location: location,
        action: action
      }
    });
  });
  return function (next) {
    return function (action) {
      switch (action.type) {
        case USER_CHANGE_PASSWORD_SUCCESS:
          {
            if (getState().user.isChangePassword) {
              dispatch(saveUserInformation({
                isChangePassword: false
              }));
              next(showToast({
                type: 'success',
                text: 'Parola a fost schimbata cu succes.'
              }));
              break;
            }
            if (getState().user.isForgotPassword) {
              dispatch(saveUserInformation({
                isForgotPassword: false
              }));
            }
            window.location.replace("".concat(window.location.protocol, "//").concat(window.location.host).concat(routes.homePage));
            break;
          }
        case USER_GENERATE_SMS_TOKEN_FAILURE:
          {
            if (action.payload.errorMessage) {
              next(showToast({
                type: 'error',
                text: action.payload.text
              }));
            }
            break;
          }
        case USER_SUBMIT_SMS_TOKEN_SUCCESS:
          {
            var firstLogin = action.payload.isFirstLogin;
            if (firstLogin) {
              dispatch(isFirstLogin());
            }
            break;
          }
        case LOCATION_CHANGE:
          {
            var _action$payload$locat = action.payload.location.location,
              pathname = _action$payload$locat.pathname,
              search = _action$payload$locat.search;
            if (Object.values(routes).includes(pathname)) {
              if (pathname === routes.accountLogin && search === '?sessionExpired=true') {
                window.localStorage.setItem('sessionExpired', true);
              }
              if (!pathname.includes(routes.accountForgotPassword)) {
                dispatch(saveUserInformation({
                  isForgotPassword: false
                }));
              }
              if (!pathname.includes(routes.accountLogin)) {
                if (window.localStorage.getItem('sessionExpired')) {
                  window.location.replace("".concat(window.location.protocol, "//").concat(window.location.host).concat(routes.accountLogin, "?sessionExpired=true"));
                } else if (window.localStorage.getItem('userLoggedOut')) {
                  window.location.replace("".concat(window.location.protocol, "//").concat(window.location.host).concat(routes.accountLogin));
                }
              }
            }
            break;
          }
        default:
          {
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
export default router;