import { LOCATIONS_FIND_COUNTIES_FAILURE, LOCATIONS_FIND_COUNTIES_SUCCESS, LOCATIONS_FIND_COUNTY_LOCALITIES_FAILURE, LOCATIONS_FIND_COUNTY_LOCALITIES_SUCCESS, LOCATIONS_FIND_LOCALITIES_FAILURE, LOCATIONS_FIND_LOCALITIES_SUCCESS, setCounties, setCountyLocalities, setLocalities } from 'actions/locations';
function locations() {
  return function (next) {
    return function (action) {
      switch (action.type) {
        case LOCATIONS_FIND_COUNTIES_SUCCESS:
          {
            next(setCounties(action.payload));
            break;
          }
        case LOCATIONS_FIND_COUNTIES_FAILURE:
          {
            next(setCounties([]));
            break;
          }
        case LOCATIONS_FIND_COUNTY_LOCALITIES_SUCCESS:
          {
            next(setCountyLocalities(action.payload));
            break;
          }
        case LOCATIONS_FIND_LOCALITIES_SUCCESS:
          {
            next(setLocalities(action.payload));
            break;
          }
        case LOCATIONS_FIND_COUNTY_LOCALITIES_FAILURE:
          {
            next(setCountyLocalities([]));
            break;
          }
        case LOCATIONS_FIND_LOCALITIES_FAILURE:
          {
            next(setLocalities([]));
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
export default locations;