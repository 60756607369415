import api from './api';
import branch from './branch';
import branchUser from './branchUser';
import card from './card';
import enrollmentFiles from './enrollmentFiles';
import enrollmentFilesItemCorrection from './enrollmentFilesItemCorrection';
import itemCorrection from './itemCorrection';
import locations from './locations';
// import logger from './logger';
import manager from './manager';
import order from './order';
import router from './router';
import sapInvoice from './sapInvoice';
import upload from './upload';
import user from './user';
export default [api, user, manager, branch, branchUser, card, upload, itemCorrection, order, locations,
//   logger,
sapInvoice, router, enrollmentFiles, enrollmentFilesItemCorrection];