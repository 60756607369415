import { bindActionCreators } from 'redux';
import BranchUsersBadge from './BranchUsersBadge';
import { branchUsersShowModalWithUsers } from 'actions/branchUser';
import { connect } from 'react-redux';
var mapStateToProps = function mapStateToProps(state) {
  return {
    branch: state.branch,
    users: state.branchUser ? state.branchUser.branchUsers : [],
    isModalWithUsersVisible: state.branchUser.isModalWithUsersVisible
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    branchUsersShowModalWithUsers: branchUsersShowModalWithUsers
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(BranchUsersBadge);