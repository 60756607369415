import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { findSapInvoices, hideRectifySapInvoiceModal, rectifySapInvoice, setPageSize, setSapCurrentPage, setSapInvoiceToRectify, showRectifySapInvoiceModal } from 'actions/sapInvoice';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { locationChange } from 'actions/user';
import SapInvoicesDashboard from './SapInvoicesDashboard';
import { showToast } from 'actions/toast';
var mapStateToProps = function mapStateToProps(state) {
  return _objectSpread(_objectSpread({}, state.sapInvoice), {}, {
    userRoleIds: state.user.roleIds,
    showRectifyModal: state.sapInvoice.showRectifyModal,
    sapInvoiceToRectify: state.sapInvoice.sapInvoiceToRectify,
    employerId: state.user.employerId
  });
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    findSapInvoices: findSapInvoices,
    hideRectifySapInvoiceModal: hideRectifySapInvoiceModal,
    rectifySapInvoice: rectifySapInvoice,
    setSapInvoiceToRectify: setSapInvoiceToRectify,
    showRectifySapInvoiceModal: showRectifySapInvoiceModal,
    showToast: showToast,
    setSapCurrentPage: setSapCurrentPage,
    setPageSize: setPageSize,
    locationChange: locationChange
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(SapInvoicesDashboard);