import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_CANCELLED_UPLOAD_FLAG, SHOW_LOADING_SCREEN, UPLOAD_SET_CONFIG, UPLOAD_SET_FILE_NAME, UPLOAD_SET_UPLOAD_ERROR, UPLOAD_SET_VALIDATION_STATUS } from 'actions/upload';
export default function upload(state, action) {
  state = state || {
    cards: null,
    selectState: 'none',
    selectedCards: [],
    currentPage: 1,
    pageCount: 1,
    pageSize: 10,
    searchTerm: null,
    correctionScreenShow: false,
    itemsValidated: false
  };
  switch (action.type) {
    case UPLOAD_SET_UPLOAD_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadError: action.payload
      });
    case SHOW_LOADING_SCREEN:
      action.payload ? window.onbeforeunload = function () {
        return true;
      } : window.onbeforeunload = undefined;
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingScreenShow: action.payload
      });
    case UPLOAD_SET_FILE_NAME:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          fileName: action.payload
        });
      }
    case UPLOAD_SET_VALIDATION_STATUS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          itemsValidated: action.payload
        });
      }
    case SET_CANCELLED_UPLOAD_FLAG:
      // eslint-disable-next-line no-case-declarations
      var statusList = _objectSpread({}, state.uploadRequestsStatus) || {};
      statusList[action.payload.requestId] = !action.payload.uploadCancelled;
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadRequestsStatus: statusList
      });
    case UPLOAD_SET_CONFIG:
      return _objectSpread(_objectSpread({}, state), {}, {
        config: action.payload
      });
    default:
      return state;
  }
}