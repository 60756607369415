import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { AUDIT_HIDE_LOADING_ICON, AUDIT_SET_ENTRIES, AUDIT_SET_FILTER, AUDIT_SET_STATE, AUDIT_SHOW_LOADING_ICON } from 'actions/audit';
export default function audit(state, action) {
  state = state || {
    filter: {
      startDate: '',
      endDate: '',
      username: '',
      pageNumber: 1,
      pageSize: 10
    },
    isAuditLoadingIconVisible: false
  };
  switch (action.type) {
    case AUDIT_SET_STATE:
      return _objectSpread(_objectSpread({}, state), action.payload);
    case AUDIT_SET_ENTRIES:
      return _objectSpread(_objectSpread({}, state), {}, {
        entries: action.payload.auditEntries,
        totalCount: action.payload.totalCount
      });
    case AUDIT_SET_FILTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        filter: _objectSpread(_objectSpread({}, state.filter), action.payload)
      });
    case AUDIT_SHOW_LOADING_ICON:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAuditLoadingIconVisible: true
      });
    case AUDIT_HIDE_LOADING_ICON:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAuditLoadingIconVisible: false
      });
    default:
      return state;
  }
}