import { setCorrectionScreenCurrentPage, setCorrectionScreenPageSize, setErrorFilter } from 'actions/enrollmentFilesItemCorrection';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DefaultItemCorrectionFooter from '../../../DefaultItemCorrection/Footer/DefaultItemCorrectionFooter';
var mapStateToProps = function mapStateToProps(state) {
  return {
    itemsToBeCorrected: state.enrollmentFiles.itemCorrection.itemsToBeCorrected,
    items: state.enrollmentFiles.itemCorrection.fileEntries,
    filter: state.enrollmentFiles.itemCorrection.filter,
    currentPage: state.enrollmentFiles.itemCorrection.currentPage,
    pageCount: state.enrollmentFiles.itemCorrection.pageCount,
    pageSize: state.enrollmentFiles.itemCorrection.pageSize,
    totalCount: state.enrollmentFiles.itemCorrection.totalCount
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setCorrectionScreenCurrentPage: setCorrectionScreenCurrentPage,
    setCorrectionScreenPageSize: setCorrectionScreenPageSize,
    setErrorFilter: setErrorFilter
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(DefaultItemCorrectionFooter);