export var BRANCH_USER_ADD = 'BRANCH_USER_ADD';
export var BRANCH_USER_FIND_REQUEST = 'BRANCH_USER_FIND_REQUEST';
export var BRANCH_USER_FIND_FAILURE = 'BRANCH_USER_FIND_FAILURE';
export var BRANCH_USER_FIND_SUCCESS = 'BRANCH_USER_FIND_SUCCESS';
export var BRANCH_USER_HIDE_MODAL_WITH_USERS = 'BRANCH_USER_HIDE_MODAL_WITH_USERS';
export var BRANCH_USER_SHOW_MODAL_WITH_USERS = 'BRANCH_USER_SHOW_MODAL_WITH_USERS';
export var SET_BRANCH_USERS = 'SET_BRANCH_USERS';
export var addBranchUser = function addBranchUser(branchUser) {
  return {
    type: BRANCH_USER_ADD,
    payload: branchUser
  };
};
export var branchUsersHideModalWithUsers = function branchUsersHideModalWithUsers(branchId) {
  return {
    type: BRANCH_USER_HIDE_MODAL_WITH_USERS,
    payload: branchId
  };
};
export var branchUsersShowModalWithUsers = function branchUsersShowModalWithUsers(branchId) {
  return {
    type: BRANCH_USER_SHOW_MODAL_WITH_USERS,
    payload: branchId
  };
};
export var findBranchUsersRequest = function findBranchUsersRequest() {
  return {
    type: BRANCH_USER_FIND_REQUEST
  };
};
export var findBranchUsersFailure = function findBranchUsersFailure(errorMessage) {
  return {
    type: BRANCH_USER_FIND_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var findBranchUsersSuccess = function findBranchUsersSuccess(branchUsers) {
  return {
    type: BRANCH_USER_FIND_SUCCESS,
    payload: branchUsers
  };
};
export var setBranchUsers = function setBranchUsers(branchUsers) {
  return {
    type: SET_BRANCH_USERS,
    payload: branchUsers
  };
};