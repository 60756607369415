import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { setItemsToBeCorrected as setEnrollmentItemsToBeCorrected, setFileName } from 'actions/enrollmentFilesItemCorrection';
import { setUploadError, setValidationStatus, UPLOAD_CSV_FILE_FAILURE, UPLOAD_CSV_FILE_SUCCESS } from 'actions/upload';
import { blockCurrentBranchSelection } from 'actions/branch';
import { fileUploadType } from '../utils/enums';
import { setItemsToBeCorrected } from 'actions/itemCorrection';
function upload(store) {
  return function (next) {
    return function (action) {
      switch (action.type) {
        case UPLOAD_CSV_FILE_SUCCESS:
          {
            var _action$payload = action.payload,
              fileUploadResults = _action$payload.fileUploadResults,
              uploadTypeId = _action$payload.uploadTypeId;
            if (uploadTypeId == fileUploadType.enrollmentFilesBulk) {
              var itemsToBeCorrected = fileUploadResults.enrollmentFileEntriesUploadModel.map(function (fileEntry, index) {
                  return _objectSpread(_objectSpread({}, fileEntry), {}, {
                    id: index
                  });
                }),
                fileName = fileUploadResults.fileName;
              store.dispatch(setFileName(fileName));
              store.dispatch(setEnrollmentItemsToBeCorrected(itemsToBeCorrected, uploadTypeId));
            } else {
              var _itemsToBeCorrected = fileUploadResults.map(function (cardEntity, index) {
                return _objectSpread(_objectSpread({}, cardEntity), {}, {
                  id: index
                });
              });
              store.dispatch(setItemsToBeCorrected(_itemsToBeCorrected, uploadTypeId));
            }
            next(setUploadError(''));
            next(setValidationStatus(false));
            break;
          }
        case UPLOAD_CSV_FILE_FAILURE:
          {
            next(setUploadError(action.payload.errorMessage));
            store.dispatch(blockCurrentBranchSelection(false));
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
export default upload;