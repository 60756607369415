import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { ADD_USER_TO_BRANCH_SUCCESS, BRANCH_DISABLE_FAILURE, BRANCH_DISABLE_SUCCESS, BRANCH_ENABLE_FAILURE, BRANCH_ENABLE_SUCCESS, BRANCH_FIND_FAILURE, BRANCH_FIND_SUCCESS, BRANCH_UPSERT_FAILURE, BRANCH_UPSERT_SUCCESS, REMOVE_USER_FROM_BRANCH_SUCCESS, REMOVE_USER_FROM_BRANCHES_EXCEPT } from '../actions/branch';
import { afterCurrentBranchWasSet, findBranches, hideModal, setBranches, setBranchesLoadedFlag, setCurrentBranch, setCurrentBranchUserIds, setUpsertBranchResult } from '../actions/branch';
import { MANAGER_DELETE_SUCCESS } from 'actions/manager';
import { showToast } from 'actions/toast';
function branch(store) {
  return function (next) {
    return function (action) {
      switch (action.type) {
        case BRANCH_FIND_SUCCESS:
          {
            // eslint-disable-next-line no-case-declarations
            var updatedBranches = action.payload || [];
            if (action.payload && action.payload.length > 1) {
              updatedBranches = action.payload.sort(function (a, b) {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
              });
              updatedBranches.splice(0, 0, {
                id: null,
                name: 'Toate punctele de lucru',
                userIds: []
              });
            }
            next(setBranches(updatedBranches));
            next(setBranchesLoadedFlag(true));
            var currentBranch = store.getState().branch.currentBranch;
            if (!currentBranch) {
              var updatedBranch = updatedBranches[0];
              next(setCurrentBranch(updatedBranch));
              next(afterCurrentBranchWasSet(updatedBranch));
            } else {
              var updatedCurrentBranch = updatedBranches.filter(function (b) {
                return b.id === currentBranch.id;
              })[0];
              next(setCurrentBranch(updatedCurrentBranch));
              next(afterCurrentBranchWasSet(updatedCurrentBranch));
            }
            break;
          }
        case BRANCH_FIND_FAILURE:
          {
            next(setBranches([]));
            break;
          }
        case ADD_USER_TO_BRANCH_SUCCESS:
          {
            var branchState = store.getState().branch,
              updatedAddBranches = branchState ? branchState.branches.map(function (b) {
                if (b.id !== null && b.id === action.payload.branchId) {
                  b.userIds.push(action.payload.userId);
                  next(setCurrentBranchUserIds(_toConsumableArray(b.userIds)));
                }
                return b;
              }) : [];
            next(setBranches(updatedAddBranches));
            break;
          }
        case REMOVE_USER_FROM_BRANCH_SUCCESS:
          {
            var _branchState = store.getState().branch,
              updatedRemoveBranches = _branchState ? _branchState.branches.map(function (b) {
                if (b.id !== null && b.id === action.payload.branchId) {
                  b.userIds = b.userIds.filter(function (uid) {
                    return uid != action.payload.userId;
                  });
                  next(setCurrentBranchUserIds(_toConsumableArray(b.userIds)));
                }
                return b;
              }) : [];
            next(setBranches(updatedRemoveBranches));
            break;
          }
        case REMOVE_USER_FROM_BRANCHES_EXCEPT:
          {
            var _action$payload = action.payload,
              userId = _action$payload.userId,
              branchId = _action$payload.branchId,
              branches = store.getState().branch.branches,
              _updatedBranches = branches.map(function (branch) {
                if (branch.id !== branchId) {
                  branch.userIds = branch.userIds.filter(function (id) {
                    return id !== userId;
                  });
                }
                return branch;
              });
            next(setBranches(_updatedBranches));
            break;
          }
        case BRANCH_UPSERT_SUCCESS:
          {
            next(setUpsertBranchResult(false, ''));
            next(setCurrentBranch(action.payload));
            next(hideModal());
            if (store.getState().branch.isModalInEditMode) {
              next(showToast({
                text: 'Punctul de lucru a fost modificat cu succes',
                type: 'success'
              }));
            } else {
              next(showToast({
                text: 'Punctul de lucru a fost adaugat cu succes',
                type: 'success'
              }));
            }
            store.dispatch(findBranches());
            break;
          }
        case BRANCH_UPSERT_FAILURE:
          {
            next(setUpsertBranchResult(true, action.payload.errorMessage));
            break;
          }
        case MANAGER_DELETE_SUCCESS:
          {
            var _branchState2 = store.getState().branch;
            var _updatedBranches2 = _branchState2 ? _branchState2.branches.map(function (b) {
              b.userIds.splice(b.userIds.indexOf(action.payload.userId), 1);
              if (b.id !== null && _branchState2.currentBranch && b.id === _branchState2.currentBranch.id) {
                next(setCurrentBranchUserIds(_toConsumableArray(b.userIds)));
              }
              return b;
            }) : [];
            next(setBranches(_updatedBranches2));
            break;
          }
        case BRANCH_DISABLE_SUCCESS:
          {
            switch (action.payload.status) {
              case 'Success':
                next(showToast({
                  type: 'success',
                  text: 'Punctul de lucru a fost dezactivat cu succes'
                }));
                store.dispatch(findBranches());
                // eslint-disable-next-line no-case-declarations
                var _branchState3 = store.getState().branch;
                store.dispatch(setCurrentBranch(_branchState3.branches[0]));
                break;
              default:
                next(showToast({
                  type: 'info',
                  title: 'Dezactivarea nu este posibila',
                  text: action.payload.text
                }));
                break;
            }
            break;
          }
        case BRANCH_DISABLE_FAILURE:
          {
            next(showToast({
              type: 'info',
              title: 'Dezactivarea nu este posibila',
              text: action.payload.errorMessage
            }));
            break;
          }
        case BRANCH_ENABLE_SUCCESS:
          {
            next(showToast({
              type: 'success',
              text: 'Punctul de lucru a fost activat cu succes'
            }));
            store.dispatch(findBranches());
            // eslint-disable-next-line no-case-declarations
            var _branchState4 = store.getState().branch;
            store.dispatch(setCurrentBranch(_branchState4.branches[0]));
            break;
          }
        case BRANCH_ENABLE_FAILURE:
          {
            next(showToast({
              type: 'info',
              title: 'Dezactivarea nu este posibila',
              text: action.payload.errorMessage
            }));
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
export default branch;