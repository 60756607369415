import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { TOGGLE_MODAL_VISIBILITY, TOGGLE_SUBMIT_BUTTON } from 'actions/customModal';
export default function customModal(state, action) {
  state = state || {
    isSubmitButtonEnabled: false,
    isModalShowable: false
  };
  switch (action.type) {
    case TOGGLE_SUBMIT_BUTTON:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSubmitButtonEnabled: action.payload
      });
    case TOGGLE_MODAL_VISIBILITY:
      return _objectSpread(_objectSpread({}, state), {}, {
        isModalShowable: action.payload
      });
    default:
      return state;
  }
}