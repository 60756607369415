var ERROR_MANDATORY_FIELD = 'Campul este obligatoriu';
var ERROR_NAME_TOO_HIGH = 'Campul trebuie sa contina cel mult 30 caractere';
var ERROR_BRANCH_USERNAME_TOO_LOW = 'Campul trebuie sa contina cel putin 3 caractere';
var ERROR_PHONE_NUMBER_INVALID = 'Numar de telefon invalid';
var ERROR_USERNAME_FORMAT = 'Format utilizator invalid. Utilizati doar litere (A-z), cifre (0-9), punct (.), minus (-). Este acceptat maxim un caracter special si nu poate fi la inceput sau la sfarsit. Exemplu: dan.popescu, dan1.popescu';
var ERROR_LAST_NAME_FORMAT = 'Numele trebuie sa contina doar litere, spatiu, minus(-) si apostrof ( \' )';
var ERROR_FIRST_NAME_FORMAT = 'Prenumele trebuie sa contina doar litere, spatiu, minus(-) si apostrof ( \' )';
var ERROR_NAME_TOO_LOW = 'Campul trebuie sa contina cel putin 2 caractere';
var nameRegex = /^([ăăáäéèíìîóöőșțúüűùĂÂÁÀÄÉÎÌÍÖÓŐȘȚÜÚŰÙA-Za-z ]+[-']?)+[ăăáäéèíìîóöőșțúüűùĂÂÁÀÄÉÎÌÍÖÓŐȘȚÜÚŰÙA-Za-z ]+$/;
var ERROR_NO_ACTIVE_BRANCHES_TO_ASSIGN_TO = 'Nu exista puncte de lucru active la care sa adaugati acest utilizator';
var MANAGER_USERNAME_FORMAT_REGEX = /^[A-Za-z0-9]+[.-]?[A-Za-z0-9]+$/;
var BRANCH_INPUT_FORMAT_REGEX = /^[a-zA-Z0-9 _\-\.,()@+&""'\/\\]*$/;
var isValidPhoneNumber = function isValidPhoneNumber(phoneNumber) {
  var onlyNumbersRegex = /^[0-9\b]+$/;
  if (phoneNumber.trim() === '') {
    return false;
  }
  if (!phoneNumber.startsWith('0') || phoneNumber.length != 10 || !onlyNumbersRegex.test(phoneNumber)) return false;
  return true;
};
var isValidBranchInputFormat = function isValidBranchInputFormat(branchName) {
  return BRANCH_INPUT_FORMAT_REGEX.test(branchName);
};
var validateManagerUserName = function validateManagerUserName(managerUserName) {
  var isManagerUserNameError, managerUserNameError;
  if (managerUserName.trim() === '') {
    isManagerUserNameError = true;
    managerUserNameError = ERROR_MANDATORY_FIELD;
  } else if (managerUserName.length < 3) {
    isManagerUserNameError = true;
    managerUserNameError = ERROR_BRANCH_USERNAME_TOO_LOW;
  } else if (managerUserName.length > 30) {
    isManagerUserNameError = true;
    managerUserNameError = ERROR_NAME_TOO_HIGH;
  } else if (!MANAGER_USERNAME_FORMAT_REGEX.test(managerUserName)) {
    isManagerUserNameError = true;
    managerUserNameError = ERROR_USERNAME_FORMAT;
  } else {
    isManagerUserNameError = false;
    managerUserNameError = '';
  }
  return {
    isManagerUserNameError: isManagerUserNameError,
    managerUserNameError: managerUserNameError
  };
};
var validateManagerLastName = function validateManagerLastName(managerLastName) {
  var isManagerLastNameError, managerLastNameError;
  if (managerLastName.trim() === '') {
    isManagerLastNameError = true;
    managerLastNameError = ERROR_MANDATORY_FIELD;
  } else if (managerLastName.length > 30) {
    isManagerLastNameError = true;
    managerLastNameError = ERROR_NAME_TOO_HIGH;
  } else if (managerLastName.length < 2) {
    isManagerLastNameError = true;
    managerLastNameError = ERROR_NAME_TOO_LOW;
  } else if (!nameRegex.test(managerLastName.trim())) {
    isManagerLastNameError = true;
    managerLastNameError = ERROR_LAST_NAME_FORMAT;
  } else {
    isManagerLastNameError = false;
    managerLastNameError = '';
  }
  return {
    isManagerLastNameError: isManagerLastNameError,
    managerLastNameError: managerLastNameError
  };
};
var validateManagerFirstName = function validateManagerFirstName(managerFirstName) {
  var isManagerFirstNameError = false,
    managerFirstNameError = '';
  if (managerFirstName.trim() === '') {
    isManagerFirstNameError = true;
    managerFirstNameError = ERROR_MANDATORY_FIELD;
  } else if (managerFirstName.length > 30) {
    isManagerFirstNameError = true;
    managerFirstNameError = ERROR_NAME_TOO_HIGH;
  } else if (managerFirstName.length < 2) {
    isManagerFirstNameError = true;
    managerFirstNameError = ERROR_NAME_TOO_LOW;
  } else if (!nameRegex.test(managerFirstName.trim())) {
    isManagerFirstNameError = true;
    managerFirstNameError = ERROR_FIRST_NAME_FORMAT;
  } else {
    isManagerFirstNameError = false;
    managerFirstNameError = '';
  }
  return {
    isManagerFirstNameError: isManagerFirstNameError,
    managerFirstNameError: managerFirstNameError
  };
};
var validateManagerPhoneNumber = function validateManagerPhoneNumber(managerPhoneNumber) {
  var isManagerPhoneNumberError, managerPhoneNumberError;
  if (managerPhoneNumber.trim() === '') {
    isManagerPhoneNumberError = true;
    managerPhoneNumberError = ERROR_MANDATORY_FIELD;
  } else if (!isValidPhoneNumber(managerPhoneNumber)) {
    isManagerPhoneNumberError = true;
    managerPhoneNumberError = ERROR_PHONE_NUMBER_INVALID;
  } else {
    isManagerPhoneNumberError = false;
    managerPhoneNumberError = '';
  }
  return {
    isManagerPhoneNumberError: isManagerPhoneNumberError,
    managerPhoneNumberError: managerPhoneNumberError
  };
};
var validateManagerRole = function validateManagerRole(managerRole) {
  var isManagerRoleError, managerRoleError;
  if (managerRole === '-1') {
    isManagerRoleError = true;
    managerRoleError = ERROR_MANDATORY_FIELD;
  } else {
    isManagerRoleError = false;
    managerRoleError = '';
  }
  return {
    isManagerRoleError: isManagerRoleError,
    managerRoleError: managerRoleError
  };
};
var validateManagerBranches = function validateManagerBranches(selectedBranches, branchOptions) {
  var isSelectedBranchesError, selectedBranchesError;
  if (selectedBranches === null || selectedBranches.length == 0) {
    var error = branchOptions.length > 0 ? ERROR_MANDATORY_FIELD : ERROR_NO_ACTIVE_BRANCHES_TO_ASSIGN_TO;
    isSelectedBranchesError = true;
    selectedBranchesError = error;
  } else {
    isSelectedBranchesError = false;
    selectedBranchesError = '';
  }
  return {
    isSelectedBranchesError: isSelectedBranchesError,
    selectedBranchesError: selectedBranchesError
  };
};
export default {
  isValidBranchInputFormat: isValidBranchInputFormat,
  isValidPhoneNumber: isValidPhoneNumber,
  validateManagerUserName: validateManagerUserName,
  validateManagerFirstName: validateManagerFirstName,
  validateManagerLastName: validateManagerLastName,
  validateManagerPhoneNumber: validateManagerPhoneNumber,
  validateManagerRole: validateManagerRole,
  validateManagerBranches: validateManagerBranches
};