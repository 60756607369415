import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { LOCATIONS_SET_COUNTIES, LOCATIONS_SET_COUNTY_LOCALITIES, LOCATIONS_SET_LOCALITIES } from 'actions/locations';
export default function locations(state, action) {
  state = state || {
    counties: [],
    countyLocalities: [],
    localities: []
  };
  switch (action.type) {
    case LOCATIONS_SET_COUNTIES:
      return _objectSpread(_objectSpread({}, state), {}, {
        counties: action.payload.map(function (county) {
          return Object.assign({}, {
            id: county,
            name: county
          });
        })
      });
    case LOCATIONS_SET_LOCALITIES:
      return _objectSpread(_objectSpread({}, state), {}, {
        localities: action.payload.map(function (locality) {
          return Object.assign({}, {
            id: locality.name,
            name: locality.name,
            county: locality.countyName
          });
        })
      });
    case LOCATIONS_SET_COUNTY_LOCALITIES:
      return _objectSpread(_objectSpread({}, state), {}, {
        countyLocalities: action.payload.map(function (locality) {
          return Object.assign({}, {
            id: locality.name,
            name: locality.name,
            county: locality.countyName
          });
        })
      });
    default:
      return state;
  }
}