import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export var USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST';
export var USER_CHANGE_PASSWORD_FAILURE = 'USER_CHANGE_PASSWORD_FAILURE';
export var USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export var USER_GENERATE_SMS_TOKEN_REQUEST = 'USER_GENERATE_SMS_TOKEN_REQUEST';
export var USER_GENERATE_SMS_TOKEN_FAILURE = 'USER_GENERATE_SMS_TOKEN_FAILURE';
export var USER_GENERATE_SMS_TOKEN_SUCCESS = 'USER_GENERATE_SMS_TOKEN_SUCCESS';
export var USER_GET_USER_DATA_REQUEST = 'USER_GET_USER_DATA_REQUEST';
export var USER_GET_USER_DATA_FAILURE = 'USER_GET_USER_DATA_FAILURE';
export var USER_GET_USER_DATA_SUCCESS = 'USER_GET_USER_DATA_SUCCESS';
export var USER_SUBMIT_SMS_TOKEN_REQUEST = 'USER_SUBMIT_SMS_TOKEN_REQUEST';
export var USER_SUBMIT_SMS_TOKEN_FAILURE = 'USER_SUBMIT_SMS_TOKEN_FAILURE';
export var USER_SUBMIT_SMS_TOKEN_SUCCESS = 'USER_SUBMIT_SMS_TOKEN_SUCCESS';
export var USER_SUBMIT_USERNAME_REQUEST = 'USER_SUBMIT_USERNAME_REQUEST';
export var USER_SUBMIT_USERNAME_FAILURE = 'USER_SUBMIT_USERNAME_FAILURE';
export var USER_SUBMIT_USERNAME_SUCCESS = 'USER_SUBMIT_USERNAME_SUCCESS';
export var USER_SUBMIT_PASSWORD_REQUEST = 'USER_SUBMIT_PASSWORD_REQUEST';
export var USER_SUBMIT_PASSWORD_FAILURE = 'USER_SUBMIT_PASSWORD_FAILURE';
export var USER_SUBMIT_PASSWORD_SUCCESS = 'USER_SUBMIT_PASSWORD_SUCCESS';
export var USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST';
export var USER_FORGOT_PASSWORD_FAILURE = 'USER_FORGOT_PASSWORD_FAILURE';
export var USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export var USER_EXPIRED_PASSWORD = 'USER_EXPIRED_PASSWORD';
export var USER_VERIFY_PASSWORD_REQUEST = 'USER_VERIFY_PASSWORD_REQUEST';
export var USER_VERIFY_PASSWORD_FAILURE = 'USER_VERIFY_PASSWORD_FAILURE';
export var USER_VERIFY_PASSWORD_SUCCESS = 'USER_VERIFY_PASSWORD_SUCCESS';
export var USER_SUBMIT_PHONE_NUMBER_REQUEST = 'USER_SUBMIT_PHONE_NUMBER_REQUEST';
export var USER_SUBMIT_PHONE_NUMBER_FAILURE = 'USER_SUBMIT_PHONE_NUMBER_FAILURE';
export var USER_SUBMIT_PHONE_NUMBER_SUCCESS = 'USER_SUBMIT_PHONE_NUMBER_SUCCESS';
export var USER_LOGOUT = 'USER_LOGOUT';
export var USER_IS_FIRST_LOGIN = 'USER_IS_FIRST_LOGIN';
export var USER_SAVE_INFORMATION = 'USER_SAVE_INFORMATION';
export var USER_SET_LOGIN_ERROR = 'USER_SET_LOGIN_ERROR';
export var USER_SET_SMS_CODE = 'USER_SET_SMS_CODE';
export var USER_PING_SERVER = 'USER_PING_SERVER';
export var USER_SET_USERNAME_CAPTCHA = 'USER_SET_USERNAME_CAPTCHA';
export var USER_SET_OTP_CAPTCHA = 'USER_SET_OTP_CAPTCHA';
export var USER_SET_PASSWORD_CAPTCHA = 'USER_SET_PASSWORD_CAPTCHA';
export var USER_SET_PHONE_NUMBER_CAPTCHA = 'USER_SET_PHONE_NUMBER_CAPTCHA';
export var USER_GENERATE_XSRF_TOKEN = 'USER_GENERATE_XSRF_TOKEN';
export var LOCATION_CHANGE = 'LOCATION_CHANGE';
export var changePasswordRequest = function changePasswordRequest(currentPassword, newPassword) {
  return {
    type: USER_CHANGE_PASSWORD_REQUEST,
    payload: {
      currentPassword: currentPassword,
      newPassword: newPassword
    }
  };
};
export var changePasswordFailure = function changePasswordFailure(errorMessage) {
  return {
    type: USER_CHANGE_PASSWORD_FAILURE,
    payload: errorMessage
  };
};
export var changePasswordSuccess = function changePasswordSuccess() {
  return {
    type: USER_CHANGE_PASSWORD_SUCCESS
  };
};
export var generateSmsTokenRequest = function generateSmsTokenRequest() {
  return {
    type: USER_GENERATE_SMS_TOKEN_REQUEST
  };
};
export var generateSmsTokenFailure = function generateSmsTokenFailure(errorMessage) {
  return {
    type: USER_GENERATE_SMS_TOKEN_FAILURE,
    payload: errorMessage
  };
};
export var generateSmsTokenSuccess = function generateSmsTokenSuccess(result) {
  return {
    type: USER_GENERATE_SMS_TOKEN_SUCCESS,
    payload: result
  };
};
export var getUserDataRequest = function getUserDataRequest() {
  return {
    type: USER_GET_USER_DATA_REQUEST
  };
};
export var getUserDataFailure = function getUserDataFailure() {
  return {
    type: USER_GET_USER_DATA_FAILURE
  };
};
export var getUserDataSuccess = function getUserDataSuccess(result) {
  return {
    type: USER_GET_USER_DATA_SUCCESS,
    payload: result
  };
};
export var submitSmsTokenRequest = function submitSmsTokenRequest(tokenVerfication) {
  return {
    type: USER_SUBMIT_SMS_TOKEN_REQUEST,
    payload: tokenVerfication
  };
};
export var submitSmsTokenFailure = function submitSmsTokenFailure(errorMessage) {
  return {
    type: USER_SUBMIT_SMS_TOKEN_FAILURE,
    payload: errorMessage
  };
};
export var submitSmsTokenSuccess = function submitSmsTokenSuccess(result) {
  return {
    type: USER_SUBMIT_SMS_TOKEN_SUCCESS,
    payload: result
  };
};
export var submitUsernameRequest = function submitUsernameRequest(userVerficiation) {
  return {
    type: USER_SUBMIT_USERNAME_REQUEST,
    payload: userVerficiation
  };
};
export var submitUsernameFailure = function submitUsernameFailure(errorMessage) {
  return {
    type: USER_SUBMIT_USERNAME_FAILURE,
    payload: errorMessage
  };
};
export var submitUsernameSuccess = function submitUsernameSuccess(result) {
  return {
    type: USER_SUBMIT_USERNAME_SUCCESS,
    payload: result
  };
};
export var submitPasswordRequest = function submitPasswordRequest(passwordVerficiation) {
  return {
    type: USER_SUBMIT_PASSWORD_REQUEST,
    payload: passwordVerficiation
  };
};
export var submitPasswordFailure = function submitPasswordFailure(errorMessage) {
  return {
    type: USER_SUBMIT_PASSWORD_FAILURE,
    payload: errorMessage
  };
};
export var submitPasswordSuccess = function submitPasswordSuccess(result) {
  return {
    type: USER_SUBMIT_PASSWORD_SUCCESS,
    payload: result
  };
};
export var verifyPasswordRequest = function verifyPasswordRequest(passwordVerficiation) {
  return {
    type: USER_VERIFY_PASSWORD_REQUEST,
    payload: passwordVerficiation
  };
};
export var verifyPasswordFailure = function verifyPasswordFailure(errorMessage) {
  return {
    type: USER_VERIFY_PASSWORD_FAILURE,
    payload: errorMessage
  };
};
export var verifyPasswordSuccess = function verifyPasswordSuccess(result) {
  return {
    type: USER_VERIFY_PASSWORD_SUCCESS,
    payload: result
  };
};
export var submitPhoneNumberRequest = function submitPhoneNumberRequest(phoneNumberVerification) {
  return {
    type: USER_SUBMIT_PHONE_NUMBER_REQUEST,
    payload: phoneNumberVerification
  };
};
export var submitPhoneNumberFailure = function submitPhoneNumberFailure(errorMessage) {
  return {
    type: USER_SUBMIT_PHONE_NUMBER_FAILURE,
    payload: errorMessage
  };
};
export var submitPhoneNumberSuccess = function submitPhoneNumberSuccess(result) {
  return {
    type: USER_SUBMIT_PHONE_NUMBER_SUCCESS,
    payload: result
  };
};
export var forgotPasswordRequest = function forgotPasswordRequest() {
  return {
    type: USER_FORGOT_PASSWORD_REQUEST
  };
};
export var forgotPasswordFailure = function forgotPasswordFailure(errorMessage) {
  return {
    type: USER_FORGOT_PASSWORD_FAILURE,
    payload: errorMessage
  };
};
export var forgotPasswordSuccess = function forgotPasswordSuccess(result) {
  return {
    type: USER_FORGOT_PASSWORD_SUCCESS,
    payload: result
  };
};
export var expiredPassword = function expiredPassword(result) {
  return {
    type: USER_EXPIRED_PASSWORD,
    payload: result
  };
};
export var logout = function logout() {
  return {
    type: USER_LOGOUT
  };
};
export var pingServer = function pingServer() {
  return {
    type: USER_PING_SERVER
  };
};
export var setLoginError = function setLoginError(errorMessage) {
  return {
    type: USER_SET_LOGIN_ERROR,
    payload: errorMessage
  };
};
export var saveUserInformation = function saveUserInformation(userInfo) {
  return {
    type: USER_SAVE_INFORMATION,
    payload: _objectSpread({}, userInfo)
  };
};
export var setSmsToken = function setSmsToken(smsToken) {
  return {
    type: USER_SET_SMS_CODE,
    payload: {
      smsToken: smsToken
    }
  };
};
export var isFirstLogin = function isFirstLogin() {
  return {
    type: USER_IS_FIRST_LOGIN
  };
};
export var generateXsrfToken = function generateXsrfToken() {
  return {
    type: USER_GENERATE_XSRF_TOKEN
  };
};
export var setUsernameCaptcha = function setUsernameCaptcha(captcha) {
  return {
    type: USER_SET_USERNAME_CAPTCHA,
    payload: captcha
  };
};
export var setPasswordCaptcha = function setPasswordCaptcha(captcha) {
  return {
    type: USER_SET_PASSWORD_CAPTCHA,
    payload: captcha
  };
};
export var setOtpCaptcha = function setOtpCaptcha(captcha) {
  return {
    type: USER_SET_OTP_CAPTCHA,
    payload: captcha
  };
};
export var setPhoneNumberCaptcha = function setPhoneNumberCaptcha(captcha) {
  return {
    type: USER_SET_PHONE_NUMBER_CAPTCHA,
    payload: captcha
  };
};
export var locationChange = function locationChange(location, action) {
  return {
    type: LOCATION_CHANGE,
    payload: {
      location: {
        location: location,
        action: action
      }
    }
  };
};