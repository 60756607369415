export var LOCATIONS_FIND_COUNTIES_REQUEST = 'LOCATIONS_FIND_COUNTIES_REQUEST';
export var LOCATIONS_FIND_COUNTIES_FAILURE = 'LOCATIONS_FIND_COUNTIES_FAILURE';
export var LOCATIONS_FIND_COUNTIES_SUCCESS = 'LOCATIONS_FIND_COUNTIES_SUCCESS';
export var LOCATIONS_FIND_COUNTY_LOCALITIES_REQUEST = 'LOCATIONS_FIND_COUNTY_LOCALITIES_REQUEST';
export var LOCATIONS_FIND_COUNTY_LOCALITIES_FAILURE = 'LOCATIONS_FIND_COUNTY_LOCALITIES_FAILURE';
export var LOCATIONS_FIND_COUNTY_LOCALITIES_SUCCESS = 'LOCATIONS_FIND_COUNTY_LOCALITIES_SUCCESS';
export var LOCATIONS_FIND_LOCALITIES_REQUEST = 'LOCATIONS_FIND_LOCALITIES_REQUEST';
export var LOCATIONS_FIND_LOCALITIES_FAILURE = 'LOCATIONS_FIND_LOCALITIES_FAILURE';
export var LOCATIONS_FIND_LOCALITIES_SUCCESS = 'LOCATIONS_FIND_LOCALITIES_SUCCESS';
export var LOCATIONS_SET_COUNTIES = 'LOCATIONS_SET_COUNTIES';
export var LOCATIONS_SET_COUNTY_LOCALITIES = 'LOCATIONS_SET_COUNTY_LOCALITIES';
export var LOCATIONS_SET_LOCALITIES = 'LOCATIONS_SET_LOCALITIES';
export var findCounties = function findCounties() {
  return {
    type: LOCATIONS_FIND_COUNTIES_REQUEST
  };
};
export var findCountiesFailure = function findCountiesFailure(errorMessage) {
  return {
    type: LOCATIONS_FIND_COUNTIES_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var findCountiesSuccess = function findCountiesSuccess(counties) {
  return {
    type: LOCATIONS_FIND_COUNTIES_SUCCESS,
    payload: counties
  };
};
export var findCountyLocalities = function findCountyLocalities(county) {
  return {
    type: LOCATIONS_FIND_COUNTY_LOCALITIES_REQUEST,
    payload: county
  };
};
export var findCountyLocalitiesFailure = function findCountyLocalitiesFailure(errorMessage) {
  return {
    type: LOCATIONS_FIND_COUNTY_LOCALITIES_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var findCountyLocalitiesSuccess = function findCountyLocalitiesSuccess(localities) {
  return {
    type: LOCATIONS_FIND_COUNTY_LOCALITIES_SUCCESS,
    payload: localities
  };
};
export var findAllLocalities = function findAllLocalities() {
  return {
    type: LOCATIONS_FIND_LOCALITIES_REQUEST
  };
};
export var findAllLocalitiesFailure = function findAllLocalitiesFailure(errorMessage) {
  return {
    type: LOCATIONS_FIND_LOCALITIES_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var findAllLocalitiesSuccess = function findAllLocalitiesSuccess(localities) {
  return {
    type: LOCATIONS_FIND_LOCALITIES_SUCCESS,
    payload: localities
  };
};
export var setCounties = function setCounties(counties) {
  return {
    type: LOCATIONS_SET_COUNTIES,
    payload: counties
  };
};
export var setCountyLocalities = function setCountyLocalities(localities) {
  return {
    type: LOCATIONS_SET_COUNTY_LOCALITIES,
    payload: localities
  };
};
export var setLocalities = function setLocalities(localities) {
  return {
    type: LOCATIONS_SET_LOCALITIES,
    payload: localities
  };
};