import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
var reactPlugin = null;
var appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
var createTelemetryService = function createTelemetryService() {
  /**
   * Initialize the Application Insights class
   * @param {string} instrumentationKey - Application Insights Instrumentation Key
   * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
   * @return {void}
   */
  var initialize = function initialize(instrumentationKey, browserHistory) {
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service');
    }
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx');
    }
    reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        isCookieUseDisabled: true,
        extensions: [reactPlugin],
        extensionConfig: _defineProperty({}, reactPlugin.identifier, {
          history: browserHistory
        })
      }
    });
    appInsights.loadAppInsights();
  };
  return {
    reactPlugin: reactPlugin,
    appInsights: appInsights,
    initialize: initialize
  };
};
export var ai = createTelemetryService();
export var getAppInsights = function getAppInsights() {
  return appInsights;
};