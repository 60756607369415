import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createManagerRequest, deleteManagerRequest, findManagersRequest, managersHideModal, managersSetModalError, managersShowModal, setCurrentManager, setManagersCurrentPage, setManagersPageSize, setManagersSearchTerm, showUpdateManagerModal, updateManagerRequest } from 'actions/manager';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ManagersDashboard from './ManagersDashboard';
import { showLoadingSpinner } from 'actions/loadingSpinner';
import { showToast } from 'actions/toast';
var mapStateToProps = function mapStateToProps(state) {
  return _objectSpread({
    currentBranch: state.branch.currentBranch,
    userRoleIds: state.user.roleIds,
    branches: state.branch.branches,
    currentManager: state.manager.currentManager
  }, state.manager);
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createManagerRequest: createManagerRequest,
    deleteManagerRequest: deleteManagerRequest,
    findManagersRequest: findManagersRequest,
    managersHideModal: managersHideModal,
    managersSetModalError: managersSetModalError,
    managersShowModal: managersShowModal,
    showUpdateManagerModal: showUpdateManagerModal,
    setManagersCurrentPage: setManagersCurrentPage,
    setManagersPageSize: setManagersPageSize,
    setManagersSearchTerm: setManagersSearchTerm,
    updateManagerRequest: updateManagerRequest,
    setCurrentManager: setCurrentManager,
    showLoadingSpinner: showLoadingSpinner,
    showToast: showToast
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagersDashboard);