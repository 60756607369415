export var CARD_FIND_CARDS_REQUEST = 'CARD_FIND_CARDS_REQUEST';
export var CARD_FIND_CARDS_FAILURE = 'CARD_FIND_CARDS_FAILURE';
export var CARD_FIND_CARDS_SUCCESS = 'CARD_FIND_CARDS_SUCCESS';
export var CARD_ADD_ITEM_TO_SELECTION = 'CARD_ADD_ITEM_TO_SELECTION';
export var CARD_REMOVE_ITEM_FROM_SELECTION = 'CARD_REMOVE_ITEM_FROM_SELECTION';
export var CARD_SELECT_ALL = 'CARD_SELECT_ALL';
export var CARD_FIND_MINIMAL_CARDS_REQUEST = 'CARD_FIND_MINIMAL_CARDS_REQUEST';
export var CARD_FIND_MINIMAL_CARDS_SUCCESS = 'CARD_FIND_MINIMAL_CARDS_SUCCESS';
export var CARD_FIND_MINIMAL_CARDS_FAILURE = 'CARD_FIND_MINIMAL_CARDS_FAILURE';
export var CARD_CLEAR_SELECTION = 'CARD_CLEAR_SELECTION';
export var SET_SELECTED_CARDS = 'SET_SELECTED_CARDS';
export var CARD_SET_CARDS = 'CARD_SET_CARDS';
export var CARD_SET_CURRENT_PAGE = 'CARD_SET_CURRENT_PAGE';
export var CARD_SET_PAGE_COUNT = 'CARD_SET_PAGE_COUNT';
export var CARD_SET_PAGE_SIZE = 'CARD_SET_PAGE_SIZE';
export var CARD_SET_TOTAL_COUNT = 'CARD_SET_TOTAL_COUNT';
export var REISSUE_PIN_REQUEST = 'REISSUE_PIN_REQUEST';
export var REISSUE_PIN_FAILURE = 'REISSUE_PIN_FAILURE';
export var REISSUE_PIN_SUCCESS = 'REISSUE_PIN_SUCCESS';
export var REISSUE_PIN_SHOW_MODAL = 'REISSUE_PIN_SHOW_MODAL';
export var REISSUE_PIN_HIDE_MODAL = 'REISSUE_PIN_HIDE_MODAL';
export var REISSUE_PIN_SET_MODAL_ERROR = 'REISSUE_PIN_SET_MODAL_ERROR';
export var REISSUE_CARD_REQUEST = 'REISSUE_CARD_REQUEST';
export var REISSUE_CARD_FAILURE = 'REISSUE_CARD_FAILURE';
export var REISSUE_CARD_SUCCESS = 'REISSUE_CARD_SUCCESS';
export var REISSUE_CARD_SHOW_MODAL = 'REISSUE_CARD_SHOW_MODAL';
export var REISSUE_CARD_HIDE_MODAL = 'REISSUE_CARD_HIDE_MODAL';
export var REISSUE_CARD_SET_MODAL_ERROR = 'REISSUE_CARD_SET_MODAL_ERROR';
export var CARD_DELETE_REQUEST = 'CARD_DELETE_REQUEST';
export var CARD_DELETE_FAILURE = 'CARD_DELETE_FAILURE';
export var CARD_DELETE_SUCCESS = 'CARD_DELETE_SUCCESS';
export var MIGRATE_CARDS_REQUEST = 'MIGRATE_CARDS_REQUEST';
export var MIGRATE_CARDS_FAILURE = 'MIGRATE_CARDS_FAILURE';
export var MIGRATE_CARDS_SUCCESS = 'MIGRATE_CARDS_SUCCESS';
export var MIGRATE_CARDS_SHOW_MODAL = 'MIGRATE_CARDS_SHOW_MODAL';
export var MIGRATE_CARDS_HIDE_MODAL = 'MIGRATE_CARDS_HIDE_MODAL';
export var CHANGE_TICKET_VALUE_REQUEST = 'CHANGE_TICKET_VALUE_REQUEST';
export var CHANGE_TICKET_VALUE_FAILURE = 'CHANGE_TICKET_VALUE_FAILURE';
export var CHANGE_TICKET_VALUE_SUCCESS = 'CHANGE_TICKET_VALUE_SUCCESS';
export var CHANGE_TICKET_VALUE_SHOW_MODAL = 'CHANGE_TICKET_VALUE_SHOW_MODAL';
export var CHANGE_TICKET_VALUE_HIDE_MODAL = 'CHANGE_TICKET_VALUE_HIDE_MODAL';
export var CARD_CONFIRMATION_SHOW_MODAL = 'CARD_CONFIRMATION_SHOW_MODAL';
export var CARD_CONFIRMATION_HIDE_MODAL = 'CARD_CONFIRMATION_HIDE_MODAL';
export var CARD_REISSUE_SHOW_CONFIRMATION_MODAL = 'CARD_REISSUE_SHOW_CONFIRMATION_MODAL';
export var CARD_REISSUE_HIDE_CONFIRMATION_MODAL = 'CARD_REISSUE_HIDE_CONFIRMATION_MODAL';
export var CARD_CONFIRM_CARDS_REQUEST = 'CARD_CONFIRM_CARDS_REQUEST';
export var CARD_CONFIRM_CARDS_FAILURE = 'CARD_CONFIRM_CARDS_FAILURE';
export var CARD_CONFIRM_CARDS_SUCCESS = 'CARD_CONFIRM_CARDS_SUCCESS';
export var CARD_REISSUE_CARDS_REQUEST = 'CARD_REISSUE_CARDS_REQUEST';
export var CARD_REISSUE_CARDS_FAILURE = 'CARD_REISSUE_CARDS_FAILURE';
export var CARD_REISSUE_CARDS_SUCCESS = 'CARD_REISSUE_CARDS_SUCCESS';
export var SET_CARDS_LOADED_FLAG = 'SET_CARDS_LOADED_FLAG';
export var CARD_SET_CARD_FILTERS = 'CARD_SET_CARD_FILTERS';
export var CARD_SET_SEND_REISSUE_CARD_IN_PROGRESS = 'CARD_SET_SEND_REISSUE_CARD_IN_PROGRESS';
export var findCardsRequest = function findCardsRequest(currentBranch) {
  return {
    type: CARD_FIND_CARDS_REQUEST,
    payload: currentBranch
  };
};
export var findCardsFailure = function findCardsFailure(errorMessage) {
  return {
    type: CARD_FIND_CARDS_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var findCardsSuccess = function findCardsSuccess(cards, totalCount) {
  return {
    type: CARD_FIND_CARDS_SUCCESS,
    payload: {
      cards: cards,
      totalCount: totalCount
    }
  };
};
export var setCards = function setCards(cards) {
  return {
    type: CARD_SET_CARDS,
    payload: cards
  };
};
export var setCardsCurrentPage = function setCardsCurrentPage(pageNumber) {
  return {
    type: CARD_SET_CURRENT_PAGE,
    payload: pageNumber
  };
};
export var setCardsPageCount = function setCardsPageCount(pageCount) {
  return {
    type: CARD_SET_PAGE_COUNT,
    payload: pageCount
  };
};
export var setCardsPageSize = function setCardsPageSize(pageSize) {
  return {
    type: CARD_SET_PAGE_SIZE,
    payload: pageSize
  };
};
export var setCardsTotalCount = function setCardsTotalCount(totalCount) {
  return {
    type: CARD_SET_TOTAL_COUNT,
    payload: totalCount
  };
};
export var clearSelection = function clearSelection() {
  return {
    type: CARD_CLEAR_SELECTION
  };
};
export var selectAllCards = function selectAllCards() {
  return {
    type: CARD_FIND_MINIMAL_CARDS_REQUEST
  };
};
export var findMinimalCardsSuccess = function findMinimalCardsSuccess(cards) {
  return {
    type: CARD_FIND_MINIMAL_CARDS_SUCCESS,
    payload: cards
  };
};
export var findMinimalCardsFailure = function findMinimalCardsFailure(errorMessage) {
  return {
    type: CARD_FIND_MINIMAL_CARDS_FAILURE,
    payload: errorMessage
  };
};
export var addCardToSelection = function addCardToSelection(card) {
  return {
    type: CARD_ADD_ITEM_TO_SELECTION,
    payload: card
  };
};
export var removeCardFromSelection = function removeCardFromSelection(card) {
  return {
    type: CARD_REMOVE_ITEM_FROM_SELECTION,
    payload: card
  };
};
export var setSelectedCards = function setSelectedCards(cards, selectionType) {
  return {
    type: SET_SELECTED_CARDS,
    payload: {
      cards: cards,
      selectionType: selectionType
    }
  };
};
export var reissuePin = function reissuePin(pinReissueModel) {
  return {
    type: REISSUE_PIN_REQUEST,
    payload: pinReissueModel
  };
};
export var reissuePinFailure = function reissuePinFailure(status, errorMessage) {
  return {
    type: REISSUE_PIN_FAILURE,
    payload: {
      status: status,
      errorMessage: errorMessage
    }
  };
};
export var reissuePinSuccess = function reissuePinSuccess(cardId, cardMigrated) {
  return {
    type: REISSUE_PIN_SUCCESS,
    payload: {
      cardId: cardId,
      cardMigrated: cardMigrated
    }
  };
};
export var showPinReissueModal = function showPinReissueModal(cardId) {
  return {
    type: REISSUE_PIN_SHOW_MODAL,
    payload: cardId
  };
};
export var hidePinReissueModal = function hidePinReissueModal() {
  return {
    type: REISSUE_PIN_HIDE_MODAL
  };
};
export var setPinReissueModalError = function setPinReissueModalError(error) {
  return {
    type: REISSUE_PIN_SET_MODAL_ERROR,
    payload: error
  };
};
export var reissueCard = function reissueCard(cardReissueModel) {
  return {
    type: REISSUE_CARD_REQUEST,
    payload: cardReissueModel
  };
};
export var reissueCardFailure = function reissueCardFailure(status, errorMessage) {
  return {
    type: REISSUE_CARD_FAILURE,
    payload: {
      status: status,
      errorMessage: errorMessage
    }
  };
};
export var reissueCardSuccess = function reissueCardSuccess(cardId, cardMigrated) {
  return {
    type: REISSUE_CARD_SUCCESS,
    payload: {
      cardId: cardId,
      cardMigrated: cardMigrated
    }
  };
};
export var showCardReissueModal = function showCardReissueModal(cardId) {
  return {
    type: REISSUE_CARD_SHOW_MODAL,
    payload: cardId
  };
};
export var hideCardReissueModal = function hideCardReissueModal() {
  return {
    type: REISSUE_CARD_HIDE_MODAL
  };
};
export var setCardReissueModalError = function setCardReissueModalError(error) {
  return {
    type: REISSUE_CARD_SET_MODAL_ERROR,
    payload: error
  };
};
export var deleteCard = function deleteCard(cardId) {
  return {
    type: CARD_DELETE_REQUEST,
    payload: cardId
  };
};
export var deleteCardFailure = function deleteCardFailure(errorMessage) {
  return {
    type: CARD_DELETE_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var deleteCardSuccess = function deleteCardSuccess(cardId, branchId) {
  return {
    type: CARD_DELETE_SUCCESS,
    payload: {
      cardId: cardId,
      branchId: branchId
    }
  };
};
export var migrateCards = function migrateCards(toBranchId) {
  return {
    type: MIGRATE_CARDS_REQUEST,
    payload: toBranchId
  };
};
export var migrateCardsFailure = function migrateCardsFailure(errorMessage) {
  return {
    type: MIGRATE_CARDS_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var migrateCardsSuccess = function migrateCardsSuccess(status, text, migratedCardsCount, remainingSelectedCardIds) {
  return {
    type: MIGRATE_CARDS_SUCCESS,
    payload: {
      status: status,
      text: text,
      migratedCardsCount: migratedCardsCount,
      remainingSelectedCardIds: remainingSelectedCardIds
    }
  };
};
export var showCardMigrationModal = function showCardMigrationModal() {
  return {
    type: MIGRATE_CARDS_SHOW_MODAL
  };
};
export var hideCardMigrationModal = function hideCardMigrationModal() {
  return {
    type: MIGRATE_CARDS_HIDE_MODAL
  };
};
export var changeTicketValue = function changeTicketValue(ticketValue) {
  return {
    type: CHANGE_TICKET_VALUE_REQUEST,
    payload: ticketValue
  };
};
export var changeTicketValueFailure = function changeTicketValueFailure(errorMessage) {
  return {
    type: CHANGE_TICKET_VALUE_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var changeTicketValueSuccess = function changeTicketValueSuccess(status, text) {
  return {
    type: CHANGE_TICKET_VALUE_SUCCESS,
    payload: {
      status: status,
      text: text
    }
  };
};
export var showChangeTicketValueModal = function showChangeTicketValueModal() {
  return {
    type: CHANGE_TICKET_VALUE_SHOW_MODAL
  };
};
export var hideChangeTicketValueModal = function hideChangeTicketValueModal() {
  return {
    type: CHANGE_TICKET_VALUE_HIDE_MODAL
  };
};
export var showModalForCardConfirmation = function showModalForCardConfirmation() {
  return {
    type: CARD_CONFIRMATION_SHOW_MODAL
  };
};
export var hideModalForCardConfirmation = function hideModalForCardConfirmation() {
  return {
    type: CARD_CONFIRMATION_HIDE_MODAL
  };
};
export var showConfirmatiomModalForCardReissue = function showConfirmatiomModalForCardReissue() {
  return {
    type: CARD_REISSUE_SHOW_CONFIRMATION_MODAL
  };
};
export var hideConfirmationModalForCardReissue = function hideConfirmationModalForCardReissue() {
  return {
    type: CARD_REISSUE_HIDE_CONFIRMATION_MODAL
  };
};
export var confirmCards = function confirmCards() {
  return {
    type: CARD_CONFIRM_CARDS_REQUEST
  };
};
export var confirmCardsFailure = function confirmCardsFailure(errorMessage) {
  return {
    type: CARD_CONFIRM_CARDS_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var confirmCardsSuccess = function confirmCardsSuccess(status, text) {
  return {
    type: CARD_CONFIRM_CARDS_SUCCESS,
    payload: {
      status: status,
      text: text
    }
  };
};
export var reissueCards = function reissueCards() {
  return {
    type: CARD_REISSUE_CARDS_REQUEST
  };
};
export var reissueCardsFailure = function reissueCardsFailure(errorMessage) {
  return {
    type: CARD_REISSUE_CARDS_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var reissueCardsSuccess = function reissueCardsSuccess(status, text) {
  return {
    type: CARD_REISSUE_CARDS_SUCCESS,
    payload: {
      status: status,
      text: text
    }
  };
};
export var setCardsLoadedFlag = function setCardsLoadedFlag(loaded) {
  return {
    type: SET_CARDS_LOADED_FLAG,
    payload: loaded
  };
};
export var setCardFilters = function setCardFilters(filters) {
  return {
    type: CARD_SET_CARD_FILTERS,
    payload: filters
  };
};
export var setSendReissueCardInProgress = function setSendReissueCardInProgress(value) {
  return {
    type: CARD_SET_SEND_REISSUE_CARD_IN_PROGRESS,
    payload: value
  };
};