import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ORDER_SET_ORDERS, SET_ORDERS_LOADED_FLAG } from 'actions/order';
export default function order(state, action) {
  state = state || {
    orders: [],
    ordersLoaded: true
  };
  switch (action.type) {
    case ORDER_SET_ORDERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        orders: _toConsumableArray(action.payload)
      });
    case SET_ORDERS_LOADED_FLAG:
      return _objectSpread(_objectSpread({}, state), {}, {
        ordersLoaded: action.payload
      });
    default:
      return state;
  }
}