import { loadAudit, setAuditFilter, setAuditState } from 'actions/audit';
import AuditDashboard from './AuditDashboard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
var mapStateToProps = function mapStateToProps(state) {
  return {
    auditTotalCount: state.audit.totalCount,
    auditEntries: state.audit.entries,
    auditFilter: state.audit.filter,
    isAuditLoadingIconVisible: state.audit.isAuditLoadingIconVisible,
    employerId: state.user.employerId
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadAudit: loadAudit,
    setAuditFilter: setAuditFilter,
    setAuditState: setAuditState
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(AuditDashboard);