export var ITEM_CORRECTION_PAGINATE_ITEMS = 'ITEM_CORRECTION_PAGINATE_ITEMS';
export var ITEM_CORRECTION_SET_ITEMS = 'ITEM_CORRECTION_SET_ITEMS';
export var ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED = 'ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED';
export var ITEM_CORRECTION_SET_CURRENT_PAGE = 'ITEM_CORRECTION_SET_CURRENT_PAGE';
export var ITEM_CORRECTION_SET_PAGE_COUNT = 'ITEM_CORRECTION_SET_PAGE_COUNT';
export var ITEM_CORRECTION_SET_PAGE_SIZE = 'ITEM_CORRECTION_SET_PAGE_SIZE';
export var ITEM_CORRECTION_SET_SEARCH_TERM = 'ITEM_CORRECTION_SET_SEARCH_TERM';
export var ITEM_CORRECTION_SET_TOTAL_COUNT = 'ITEM_CORRECTION_SET_TOTAL_COUNT';
export var ITEM_CORRECTION_SET_ERROR_FILTER = 'ITEM_CORRECTION_SET_ERROR_FILTER';
export var ITEM_CORRECTION_ADD_ITEM_TO_SELECTION = 'ITEM_CORRECTION_ADD_ITEM_TO_SELECTION';
export var ITEM_CORRECTION_REMOVE_ITEM_FROM_SELECTION = 'ITEM_CORRECTION_REMOVE_ITEM_FROM_SELECTION';
export var ITEM_CORRECTION_SELECT_ALL = 'ITEM_CORRECTION_SELECT_ALL';
export var ITEM_CORRECTION_CLEAR_SELECTION = 'ITEM_CORRECTION_CLEAR_SELECTION';
export var ITEM_CORRETION_REMOVE_SELECTED_ITEMS = 'ITEM_CORRETION_REMOVE_SELECTED_ITEMS';
export var ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_REQUEST = 'ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_REQUEST';
export var ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_FAILURE = 'ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_FAILURE';
export var ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_SUCCESS = 'ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_SUCCESS';
export var ITEM_CORRECTION_CORRECT_ITEM = 'ITEM_CORRECTION_CORRECT_ITEM';
export var ITEM_CORRECTION_SET_FILTERED_ITEMS = 'ITEM_CORRECTION_SET_FILTERED_ITEMS';
export var ITEM_CORRECTION_SET_SEND_CORRECTED_ITEMS_IN_PROGRESS = 'ITEM_CORRECTION_SET_SEND_CORRECTED_ITEMS_IN_PROGRESS';
export var ITEM_CORRECTION_SET_IS_CAPTCHA_VALID = 'ITEM_CORRECTION_SET_IS_CAPTCHA_VALID';
export var paginateItemsToBeCorrected = function paginateItemsToBeCorrected(items) {
  var pageNumber = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var pageSize = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
  var lastNameOrCnp = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  return {
    type: ITEM_CORRECTION_PAGINATE_ITEMS,
    payload: {
      items: items,
      pageNumber: pageNumber,
      pageSize: pageSize,
      lastNameOrCnp: lastNameOrCnp
    }
  };
};
export var setItemsToBeCorrected = function setItemsToBeCorrected(items, uploadTypeId) {
  return {
    type: ITEM_CORRECTION_SET_ITEMS_TO_BE_CORRECTED,
    payload: {
      items: items,
      uploadTypeId: uploadTypeId
    }
  };
};
export var setCorrectionScreenItems = function setCorrectionScreenItems(items) {
  return {
    type: ITEM_CORRECTION_SET_ITEMS,
    payload: items
  };
};
export var correctItem = function correctItem(newItem) {
  return {
    type: ITEM_CORRECTION_CORRECT_ITEM,
    payload: newItem
  };
};
export var sendCorrectedItemsRequest = function sendCorrectedItemsRequest(captchaModel) {
  var reCaptcha = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return {
    type: ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_REQUEST,
    payload: {
      captchaModel: captchaModel,
      reCaptcha: reCaptcha
    }
  };
};
export var sendCorrectedItemsFailure = function sendCorrectedItemsFailure(items, uploadTypeId) {
  return {
    type: ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_FAILURE,
    payload: {
      items: items,
      uploadTypeId: uploadTypeId
    }
  };
};
export var sendCorrectedItemsSuccess = function sendCorrectedItemsSuccess() {
  return {
    type: ITEM_CORRECTION_SEND_CORRECTED_ITEMS_TO_SERVER_SUCCESS
  };
};
export var setCorrectionScreenCurrentPage = function setCorrectionScreenCurrentPage(pageNumber) {
  return {
    type: ITEM_CORRECTION_SET_CURRENT_PAGE,
    payload: pageNumber
  };
};
export var setCorrectionScreenPageCount = function setCorrectionScreenPageCount(pageCount) {
  return {
    type: ITEM_CORRECTION_SET_PAGE_COUNT,
    payload: pageCount
  };
};
export var setCorrectionScreenPageSize = function setCorrectionScreenPageSize(pageSize) {
  return {
    type: ITEM_CORRECTION_SET_PAGE_SIZE,
    payload: pageSize
  };
};
export var setCorrectionScreenSearchTerm = function setCorrectionScreenSearchTerm(searchTerm) {
  return {
    type: ITEM_CORRECTION_SET_SEARCH_TERM,
    payload: searchTerm
  };
};
export var setCorrectionScreenTotalCount = function setCorrectionScreenTotalCount(totalCount) {
  return {
    type: ITEM_CORRECTION_SET_TOTAL_COUNT,
    payload: totalCount
  };
};
export var setErrorFilter = function setErrorFilter(filter) {
  return {
    type: ITEM_CORRECTION_SET_ERROR_FILTER,
    payload: filter
  };
};
export var correctionScreenClearSelection = function correctionScreenClearSelection() {
  return {
    type: ITEM_CORRECTION_CLEAR_SELECTION
  };
};
export var correctionScreenSelectAllItems = function correctionScreenSelectAllItems() {
  return {
    type: ITEM_CORRECTION_SELECT_ALL
  };
};
export var correctionScreenAddItemToSelection = function correctionScreenAddItemToSelection(itemId) {
  return {
    type: ITEM_CORRECTION_ADD_ITEM_TO_SELECTION,
    payload: itemId
  };
};
export var correctionScreenRemoveItemFromSelection = function correctionScreenRemoveItemFromSelection(itemId) {
  return {
    type: ITEM_CORRECTION_REMOVE_ITEM_FROM_SELECTION,
    payload: itemId
  };
};
export var correctionScreenRemoveSelectedItems = function correctionScreenRemoveSelectedItems() {
  return {
    type: ITEM_CORRETION_REMOVE_SELECTED_ITEMS
  };
};
export var setFilteredItems = function setFilteredItems(items) {
  return {
    type: ITEM_CORRECTION_SET_FILTERED_ITEMS,
    payload: items
  };
};
export var setSendCorrectedItemsInProgress = function setSendCorrectedItemsInProgress(value) {
  return {
    type: ITEM_CORRECTION_SET_SEND_CORRECTED_ITEMS_IN_PROGRESS,
    payload: value
  };
};
export var setIsCaptchaValid = function setIsCaptchaValid(value) {
  return {
    type: ITEM_CORRECTION_SET_IS_CAPTCHA_VALID,
    payload: value
  };
};