export default {
  notLoggedIn: 'NotLoggedIn',
  invalidUser: 'InternalError',
  invalidPassword: 'InvalidPassword',
  invalidPasswordFormat: 'InvalidPasswordFormat',
  invalidPhoneNumber: 'InvalidPhoneNumber',
  failedToUpdatePassword: 'FailedToUpdatePassword',
  passwordAlreadyUsed: 'PasswordAlreadyUsed',
  passwordsDoNotMatch: 'PasswordsDoNotMatch',
  otpNotGenerated: 'OtpNotGenerated',
  invalidOtp: 'InvalidOtp',
  serverError: 'ServerError',
  wrongFileExtension: 'WrongFileExtension',
  wrongFileFormat: 'WrongFileFormat',
  fileUploadFailed: 'FileUploadFailed',
  fieldRequired: 'FieldRequired',
  fileMaxSizeExceeded: 'FileMaxSizeExceeded',
  noDataFound: 'NoDataFound',
  duplicateRecordsFound: 'DuplicateRecordsFound',
  branchCreationFailed: 'BranchCreationFailed',
  branchDuplicated: 'BranchDuplicated',
  unauthorized: 'Unauthorized',
  otpExpired: 'OtpExpired',
  passwordResetInvalid: 'PasswordResetInvalid',
  termsAndConditionsNotAccepted: 'TermsAndConditionsNotAccepted',
  modalInvalid: 'ModalInvalid',
  passwordExpired: 'PasswordExpired',
  invalidCaptcha: 'InvalidCaptcha',
  enterPasswordInvalidCaptcha: 'EnterPasswordInvalidCaptcha',
  enterPasswordBruteForceAttempt: 'EnterPasswordBruteForceAttempt',
  enterPasswordBotDetected: 'EnterPasswordBotDetected',
  enterUsernameInvalidCaptcha: 'EnterUsernameInvalidCaptcha',
  enterUsernameBruteForceAttempt: 'EnterUsernameBruteForceAttempt',
  enterUsernameBotDetected: 'EnterUsernameBotDetected',
  enterOtpInvalidCaptcha: 'EnterOtpInvalidCaptcha',
  enterOtpBruteForceAttempt: 'EnterOtpBruteForceAttempt',
  enterOtpBotDetected: 'EnterOtpBotDetected',
  enterPhoneNumberInvalidCaptcha: 'EnterPhoneNumberInvalidCaptcha',
  enterPhoneNumberBruteForceAttempt: 'EnterPhoneNumberBruteForceAttempt',
  enterPhoneNumberBotDetected: 'EnterPhoneNumberBotDetected',
  changePasswordInvalidCaptcha: 'ChangePasswordInvalidCaptcha',
  changePasswordBruteForceAttempt: 'ChangePasswordBruteForceAttempt',
  changePasswordBotDetected: 'ChangePasswordBotDetected',
  onlyCsvFile: 'OnlyCsvFile',
  genericErrorMessage: 'Ups a aparut o eroare. Te rugam sa incerci din nou.',
  captchaRequired: 'CaptchaRequired',
  duplicateIBAN: 'DuplicateIBAN',
  nullIBAN: 'NullIBAN',
  botDetected: 'BotDetected'
};