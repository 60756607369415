import { findManagersRequest, MANAGER_CREATE_FAILURE, MANAGER_CREATE_SUCCESS, MANAGER_DELETE_FAILURE, MANAGER_DELETE_SUCCESS, MANAGER_FIND_FAILURE, MANAGER_FIND_SUCCESS, MANAGER_SET_PAGE_SIZE, MANAGER_SET_SEARCH_TERM, MANAGER_UPDATE_FAILURE, MANAGER_UPDATE_SUCCESS, managersSetModalError, setManagers, setManagersCurrentPage, setManagersLoadedFlag, setManagersPageCount, setManagersSearchTerm, setManagersTotalCount } from 'actions/manager';
import { addBranchUser } from 'actions/branchUser';
import { addUserToBranchSuccess } from 'actions/branch';
import { findBranches } from '../actions/branch';
import { showToast } from 'actions/toast';
function manager(store) {
  return function (next) {
    return function (action) {
      switch (action.type) {
        case MANAGER_FIND_FAILURE:
          {
            next(setManagers([]));
            next(setManagersTotalCount(0));
            break;
          }
        case MANAGER_FIND_SUCCESS:
          {
            var pageSize = store.getState().manager.pageSize,
              _action$payload = action.payload,
              entities = _action$payload.entities,
              totalCount = _action$payload.totalCount;
            next(setManagers(entities));
            next(setManagersTotalCount(totalCount));
            next(setManagersPageCount(Math.ceil(totalCount / pageSize)));
            next(setManagersLoadedFlag(true));
            break;
          }
        case MANAGER_SET_PAGE_SIZE:
          {
            next(setManagersCurrentPage(1));
            break;
          }
        case MANAGER_SET_SEARCH_TERM:
          {
            next(setManagersCurrentPage(1));
            if (action.payload === '') {
              var searchBarInput = document.getElementById('search-bar-input-users');
              searchBarInput ? searchBarInput.value = '' : {};
            }
            break;
          }
        case MANAGER_CREATE_SUCCESS:
          {
            var user = action.payload.user;
            store.dispatch(addBranchUser(user));
            store.dispatch(addUserToBranchSuccess(user.userId, user.branchIds[0]));
            store.dispatch(findBranches());
            store.dispatch(setManagersSearchTerm(''));
            store.dispatch(findManagersRequest());
            if (action.payload.showToast) {
              next(showToast({
                type: 'success',
                text: "Managerul '".concat(user.lastName, " ").concat(user.firstName, "' a fost adaugat cu succes.")
              }));
            }
            break;
          }
        case MANAGER_CREATE_FAILURE:
          {
            store.dispatch(managersSetModalError(action.payload));
            break;
          }
        case MANAGER_UPDATE_SUCCESS:
          {
            var _user = action.payload.user;
            if (action.payload.showToast) {
              next(showToast({
                type: 'success',
                text: "Managerul '".concat(_user.lastName, " ").concat(_user.firstName, "' a fost editat cu succes.")
              }));
            }
            var currentPage = store.getState().manager.currentPage;
            store.dispatch(setManagersCurrentPage(currentPage));
            store.dispatch(findManagersRequest());
            store.dispatch(findBranches());
            break;
          }
        case MANAGER_UPDATE_FAILURE:
          {
            if (action.payload.errorMessage === 'Unauthorized') next(showToast({
              type: 'error',
              text: 'Nu esti autorizat pentru aceasta actiune.'
            }));else store.dispatch(managersSetModalError(action.payload));
            break;
          }
        case MANAGER_DELETE_SUCCESS:
          {
            next(showToast({
              type: 'success',
              text: "Managerul '".concat(action.payload.userFullName, "' a fost sters cu succes.")
            }));
            var _store$getState$manag = store.getState().manager,
              managers = _store$getState$manag.managers,
              _currentPage = _store$getState$manag.currentPage;
            var pageNumber = managers.length == 1 ? _currentPage - 1 : _currentPage;
            if (pageNumber < 1) pageNumber = 1;
            store.dispatch(setManagersCurrentPage(pageNumber));
            store.dispatch(findManagersRequest());
            break;
          }
        case MANAGER_DELETE_FAILURE:
          {
            if (action.payload.errorMessage === 'Unauthorized') next(showToast({
              type: 'error',
              text: 'Nu esti autorizat pentru aceasta actiune.'
            }));else next(showToast({
              type: 'error',
              text: 'Managerul nu a putut fi sters.'
            }));
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
export default manager;