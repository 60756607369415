export var FIND_ENROLLMENT_FILES_REQUEST = 'FIND_ENDROLLMENT_FILES_REQUEST';
export var FIND_ENROLLMENT_FILES_FAILURE = 'FIND_ENDROLLMENT_FILES_FAILURE';
export var FIND_ENROLLMENT_FILES_SUCCESS = 'FIND_ENDROLLMENT_FILES_SUCCESS';
export var FETCH_ENROLLMENT_FILE_STATUSES = 'FETCH_ENROLLMENT_FILE_STATUSES';
export var SET_ENROLLMENT_FILES = 'SET_ENDROLLMENT_FILES';
export var SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export var SET_PAGE_COUNT = 'SET_PAGE_COUNT';
export var SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export var SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export var SET_ENROLLMENT_FILE_STATUSES = 'SET_ENROLLMENT_FILE_STATUSES';
export var SET_SELECTED_FILE = 'SET_SELECTED_FILE';
export var CANCEL_FILE = 'CANCEL_FILE';
export var CANCEL_FILE_SUCCESS = 'CANCEL_FILE_SUCCESS';
export var CANCEL_FILE_FAILURE = 'CANCEL_FILE_FAILURE';
export var SET_ENROLLMENT_FILES_LOADED_FLAG = 'SET_ENDROLLMENT_FILES_LOADED_FLAG';
export var SET_ENROLLMENT_FILES_FILTERS = 'SET_ENROLLMENT_FILES_FILTERS';
export var ENROLLMENT_FILES_DOWNLOAD_TEMPLATE_REQUEST = 'ENROLLMENT_FILES_DOWNLOAD_TEMPLATE_REQUEST';
export var findEnrollmentFilesRequest = function findEnrollmentFilesRequest() {
  return {
    type: FIND_ENROLLMENT_FILES_REQUEST
  };
};
export var findEnrollmentFilesFailure = function findEnrollmentFilesFailure(errorMessage) {
  return {
    type: FIND_ENROLLMENT_FILES_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var findEnrollmentFilesSuccess = function findEnrollmentFilesSuccess(enrollmentFiles, totalCount) {
  return {
    type: FIND_ENROLLMENT_FILES_SUCCESS,
    payload: {
      enrollmentFiles: enrollmentFiles,
      totalCount: totalCount
    }
  };
};
export var fetchEnrollmentFileStatuses = function fetchEnrollmentFileStatuses() {
  return {
    type: FETCH_ENROLLMENT_FILE_STATUSES
  };
};
export var cancelFileSuccess = function cancelFileSuccess() {
  return {
    type: CANCEL_FILE_SUCCESS,
    payload: {}
  };
};
export var cancelFileFailure = function cancelFileFailure() {
  return {
    type: CANCEL_FILE_FAILURE,
    payload: {}
  };
};
export var setEnrollmentFiles = function setEnrollmentFiles(enrollmentFiles) {
  return {
    type: SET_ENROLLMENT_FILES,
    payload: enrollmentFiles
  };
};
export var setEnrollmentFilesCurrentPage = function setEnrollmentFilesCurrentPage(pageNumber) {
  return {
    type: SET_CURRENT_PAGE,
    payload: pageNumber
  };
};
export var setEnrollmentFilesPageCount = function setEnrollmentFilesPageCount(pageCount) {
  return {
    type: SET_PAGE_COUNT,
    payload: pageCount
  };
};
export var setEnrollmentFilesPageSize = function setEnrollmentFilesPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize
  };
};
export var setEnrollmentFilesTotalCount = function setEnrollmentFilesTotalCount(totalCount) {
  return {
    type: SET_TOTAL_COUNT,
    payload: totalCount
  };
};
export var setEnrollmentFileStatuses = function setEnrollmentFileStatuses(enrollmentFileStatuses) {
  return {
    type: SET_ENROLLMENT_FILE_STATUSES,
    payload: enrollmentFileStatuses
  };
};
export var setSelectedFile = function setSelectedFile(selectedFile) {
  return {
    type: SET_SELECTED_FILE,
    payload: selectedFile
  };
};
export var setEnrollmentFilesLoadedFlag = function setEnrollmentFilesLoadedFlag(loaded) {
  return {
    type: SET_ENROLLMENT_FILES_LOADED_FLAG,
    payload: loaded
  };
};
export var setEnrollmentFilesFilters = function setEnrollmentFilesFilters(filters) {
  return {
    type: SET_ENROLLMENT_FILES_FILTERS,
    payload: filters
  };
};
export var downloadEnrollmentFileTemplate = function downloadEnrollmentFileTemplate() {
  return {
    type: ENROLLMENT_FILES_DOWNLOAD_TEMPLATE_REQUEST
  };
};
export var cancelFile = function cancelFile(fileId) {
  return {
    type: CANCEL_FILE,
    payload: fileId
  };
};