import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { AFTER_CURRENT_BRANCH_WAS_SET, afterCurrentBranchWasSet, setCurrentBranch } from 'actions/branch';
import { CARD_CONFIRM_CARDS_FAILURE, CARD_CONFIRM_CARDS_SUCCESS, CARD_DELETE_FAILURE, CARD_DELETE_SUCCESS, CARD_FIND_CARDS_FAILURE, CARD_FIND_CARDS_SUCCESS, CARD_FIND_MINIMAL_CARDS_FAILURE, CARD_FIND_MINIMAL_CARDS_SUCCESS, CARD_REISSUE_CARDS_FAILURE, CARD_REISSUE_CARDS_SUCCESS, CARD_SET_PAGE_SIZE, CHANGE_TICKET_VALUE_FAILURE, CHANGE_TICKET_VALUE_SUCCESS, clearSelection, findCardsRequest, hideCardReissueModal, hidePinReissueModal, MIGRATE_CARDS_FAILURE, MIGRATE_CARDS_SUCCESS, REISSUE_CARD_FAILURE, REISSUE_CARD_SUCCESS, REISSUE_PIN_FAILURE, REISSUE_PIN_SUCCESS, removeCardFromSelection, setCardFilters, setCardReissueModalError, setCards, setCardsCurrentPage, setCardsLoadedFlag, setCardsPageCount, setCardsTotalCount, setPinReissueModalError, setSelectedCards, setSendReissueCardInProgress } from 'actions/card';
import { cardStatus, selectionType } from '../utils/enums';
import { findOrders } from 'actions/order';
import { hideLoadingSpinner } from 'actions/loadingSpinner';
import history from 'utils/history';
import routes from 'utils/routes';
import { showToast } from 'actions/toast';
function card(store) {
  return function (next) {
    return function (action) {
      switch (action.type) {
        case CARD_FIND_CARDS_SUCCESS:
          {
            var _store$getState$card = store.getState().card,
              pageSize = _store$getState$card.pageSize,
              selectedCards = _store$getState$card.selectedCards,
              filters = _store$getState$card.filters,
              _action$payload = action.payload,
              cards = _action$payload.cards,
              totalCount = _action$payload.totalCount;
            next(setCards(cards));
            next(setCardsPageCount(Math.ceil(totalCount / pageSize)));
            next(setCardsTotalCount(totalCount));
            next(setCardsLoadedFlag(true));
            if (!cards || cards.length == 0) {
              next(clearSelection());
            } else if (selectedCards && selectedCards.length > 0) {
              var newSelectedCards = getSelectedCardsAfterFind(selectedCards, filters);
              next(setSelectedCards(newSelectedCards));
            }
            break;
          }
        case CARD_FIND_CARDS_FAILURE:
          {
            next(setCards([]));
            next(setCardsPageCount(1));
            next(setCardsTotalCount(0));
            break;
          }
        case CARD_SET_PAGE_SIZE:
          {
            next(setCardsCurrentPage(1));
            break;
          }
        case AFTER_CURRENT_BRANCH_WAS_SET:
          {
            var pathname = history.location.pathname;
            if (pathname.includes(routes.dashboardCards) && action.payload) {
              var id = action.payload.id;
              store.dispatch(setCardsLoadedFlag(false));
              var searchBarInput = document.getElementById('search-bar-input-cards');
              if (searchBarInput) {
                searchBarInput.value = '';
              }
              store.dispatch(setCardsCurrentPage(1));
              store.dispatch(findOrders(id));
              store.dispatch(setCardFilters({
                firstOrLastName: null,
                cnp: null,
                statusId: null,
                ticketValue: null,
                orderId: null
              }));
              store.dispatch(findCardsRequest(action.payload));
              next(clearSelection());
            }
            break;
          }
        case REISSUE_CARD_SUCCESS:
          {
            var _store$getState$card2 = store.getState().card,
              _cards = _store$getState$card2.cards,
              currentPage = _store$getState$card2.currentPage,
              _action$payload2 = action.payload,
              cardId = _action$payload2.cardId,
              cardMigrated = _action$payload2.cardMigrated;
            var pageNumber = _cards.length == 1 ? currentPage - 1 : currentPage;
            if (pageNumber < 1) pageNumber = 1;
            if (cardMigrated) {
              var updatedCurrentBranch = {
                id: null,
                name: 'Toate punctele de lucru'
              };
              store.dispatch(setCurrentBranch(updatedCurrentBranch)).then(function () {
                store.dispatch(afterCurrentBranchWasSet(updatedCurrentBranch));
              });
            }
            var currentBranch = store.getState().branch.currentBranch,
              currentBranchId = currentBranch && currentBranch.id ? currentBranch.id : null;
            store.dispatch(findOrders(currentBranchId)).then(function () {
              next(showToast({
                type: 'info',
                title: 'Reemitere Card Inregistrata',
                text: 'Solicitarea de reemitere card a fost trimisa spre procesare.'
              }));
              store.dispatch(setCardsCurrentPage(pageNumber));
              store.dispatch(removeCardFromSelection({
                cardId: cardId
              }));
              store.dispatch(findCardsRequest());
              next(setSendReissueCardInProgress(false));
              store.dispatch(hideCardReissueModal());
            });
            break;
          }
        case REISSUE_CARD_FAILURE:
          {
            next(setSendReissueCardInProgress(false));
            if (action.payload.status) {
              next(setCardReissueModalError(action.payload.errorMessage));
            } else {
              next(showToast({
                type: 'error',
                text: action.payload.errorMessage
              }));
              next(hideCardReissueModal());
              store.dispatch(clearSelection());
            }
            break;
          }
        case REISSUE_PIN_SUCCESS:
          {
            next(showToast({
              type: 'info',
              title: 'Reemitere Pin Inregistrata',
              text: 'Noul cod va fi trimis in scurt timp pe numarul de telefon al angajatului.'
            }));
            var _store$getState$card3 = store.getState().card,
              _cards2 = _store$getState$card3.cards,
              _currentPage = _store$getState$card3.currentPage,
              _action$payload3 = action.payload,
              _cardId = _action$payload3.cardId,
              _cardMigrated = _action$payload3.cardMigrated;
            var _pageNumber = _cards2.length == 1 ? _currentPage - 1 : _currentPage;
            if (_pageNumber < 1) _pageNumber = 1;
            store.dispatch(hidePinReissueModal());
            store.dispatch(setCardsCurrentPage(_pageNumber));
            store.dispatch(findCardsRequest());
            if (_cardMigrated) {
              store.dispatch(removeCardFromSelection(_cardId));
            }
            break;
          }
        case REISSUE_PIN_FAILURE:
          {
            if (action.payload.status) {
              next(setPinReissueModalError(action.payload.errorMessage));
            } else {
              next(showToast({
                type: 'error',
                text: action.payload.errorMessage
              }));
              next(hidePinReissueModal());
            }
            break;
          }
        case CARD_DELETE_SUCCESS:
          {
            var _store$getState$card4 = store.getState().card,
              _cards3 = _store$getState$card4.cards,
              _currentPage2 = _store$getState$card4.currentPage,
              deletedCard = action.payload;
            var _pageNumber2 = _cards3.length == 1 ? _currentPage2 - 1 : _currentPage2;
            if (_pageNumber2 < 1) _pageNumber2 = 1;
            next(showToast({
              type: 'success',
              text: 'Cardul a fost sters cu succes.'
            }));
            store.dispatch(setCardsCurrentPage(_pageNumber2));
            store.dispatch(removeCardFromSelection(deletedCard));
            var cardsLeft = _cards3.filter(function (c) {
              return c.id !== deletedCard.cardId;
            });
            if (!cardsLeft || cardsLeft.length === 0) {
              store.dispatch(findOrders(deletedCard.branchId)).then(function () {
                store.dispatch(findCardsRequest());
              });
            } else {
              store.dispatch(findCardsRequest());
            }
            next(hideLoadingSpinner());
            break;
          }
        case CARD_DELETE_FAILURE:
          {
            next(showToast({
              type: 'error',
              text: action.payload.errorMessage
            }));
            store.dispatch(clearSelection());
            break;
          }
        case MIGRATE_CARDS_SUCCESS:
          {
            var toast = {};
            switch (action.payload.status) {
              case 'Success':
                toast = {
                  type: 'success',
                  text: action.payload.text
                };
                break;
              case 'Warning':
                toast = {
                  type: 'warning',
                  text: action.payload.text
                };
                break;
              case 'Error':
                toast = {
                  type: 'error',
                  text: action.payload.text
                };
                break;
              default:
                toast = {
                  type: 'info',
                  title: 'Migrarea nu este posibila',
                  text: action.payload.text
                };
                break;
            }
            next(showToast(toast));
            if (action.payload.status !== 'Error') {
              var _store$getState$card5 = store.getState().card,
                selectState = _store$getState$card5.selectState,
                _selectedCards = _store$getState$card5.selectedCards,
                _cards4 = _store$getState$card5.cards,
                _currentBranch = store.getState().branch.currentBranch;
              var _cardsLeft = _cards4.filter(function (card) {
                return _selectedCards.map(function (s) {
                  return s.cardId;
                }).includes(card.id) === false;
              });
              if (!_cardsLeft || _cardsLeft.length === 0) {
                store.dispatch(findOrders(_currentBranch.id)).then(function () {
                  return store.dispatch(findCardsRequest());
                });
              } else {
                store.dispatch(findCardsRequest());
              }
              var isAllBranchesSelected = _currentBranch && (_currentBranch.id === null || _currentBranch.id === undefined),
                newSelectState = isAllBranchesSelected ? selectState : selectionType.none,
                newSelectedCardsList = isAllBranchesSelected ? _selectedCards : [];
              store.dispatch(setSelectedCards(newSelectedCardsList, newSelectState));
            }
            break;
          }
        case MIGRATE_CARDS_FAILURE:
          {
            next(showToast({
              type: 'error',
              text: action.payload.errorMessage
            }));
            break;
          }
        case CHANGE_TICKET_VALUE_SUCCESS:
          {
            var _toast = {};
            switch (action.payload.status) {
              case 'Success':
                _toast = {
                  type: 'success',
                  text: action.payload.text
                };
                break;
              case 'Warning':
                _toast = {
                  type: 'warning',
                  text: action.payload.text
                };
                break;
              case 'Error':
                _toast = {
                  type: 'error',
                  text: action.payload.text
                };
                break;
              default:
                _toast = {
                  type: 'info',
                  text: action.payload.text
                };
                break;
            }
            next(showToast(_toast));
            store.dispatch(findCardsRequest());
            break;
          }
        case CHANGE_TICKET_VALUE_FAILURE:
          {
            next(showToast({
              type: 'error',
              text: action.payload.errorMessage
            }));
            break;
          }
        case CARD_CONFIRM_CARDS_FAILURE:
          {
            next(showToast({
              type: 'error',
              text: action.payload.errorMessage
            }));
            break;
          }
        case CARD_CONFIRM_CARDS_SUCCESS:
          {
            switch (action.payload.status) {
              case 'Success':
                next(showToast({
                  type: 'success',
                  text: action.payload.text
                }));
                break;
              case 'Warning':
                next(showToast({
                  type: 'warning',
                  text: action.payload.text
                }));
                break;
              default:
                next(showToast({
                  type: 'info',
                  title: 'Operatiunea nu este posibila',
                  text: action.payload.text
                }));
                break;
            }
            var _selectedCards2 = store.getState().card.selectedCards;
            var updatedList = _selectedCards2.map(function (card) {
              return _objectSpread(_objectSpread({}, card), {}, {
                statusId: cardStatus.confirmed
              });
            });
            store.dispatch(setSelectedCards(updatedList));
            store.dispatch(findCardsRequest());
            break;
          }
        case CARD_REISSUE_CARDS_SUCCESS:
          {
            var _currentBranch2 = store.getState().branch.currentBranch,
              _currentBranchId = _currentBranch2 ? _currentBranch2.id : null;
            store.dispatch(findOrders(_currentBranchId, false)).then(function () {
              store.dispatch(findCardsRequest());
              next(clearSelection());
              next(hideLoadingSpinner());
              switch (action.payload.status) {
                case 'Success':
                  next(showToast({
                    type: 'success',
                    text: action.payload.text
                  }));
                  break;
                case 'Warning':
                  next(showToast({
                    type: 'warning',
                    text: action.payload.text
                  }));
                  break;
                default:
                  next(showToast({
                    type: 'info',
                    title: 'Operatiunea nu este posibila',
                    text: action.payload.text
                  }));
                  break;
              }
            });
            break;
          }
        case CARD_REISSUE_CARDS_FAILURE:
          {
            next(showToast({
              type: 'error',
              text: action.payload.errorMessage
            }));
            break;
          }
        case CARD_FIND_MINIMAL_CARDS_SUCCESS:
          {
            var _cards5 = action.payload;
            next(setSelectedCards(_cards5, selectionType.all));
            next(setCardsLoadedFlag(true));
            break;
          }
        case CARD_FIND_MINIMAL_CARDS_FAILURE:
          {
            next(showToast({
              type: 'error',
              text: action.payload
            }));
            next(setCardsLoadedFlag(true));
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
function getSelectedCardsAfterFind(selectedCards, filters) {
  var nameFiltered = filters.firstOrLastName && selectedCards.filter(filterByFirstLastName(filters.firstOrLastName.toLowerCase())),
    cnpFiltered = filters.cnp && selectedCards.filter(function (sc) {
      return sc.cnp.startsWith(filters.cnp);
    }),
    statusFiltered = Number.isInteger(filters.statusId) ? filters.statusId > -1 && selectedCards.filter(function (sc) {
      return sc.statusId === filters.statusId;
    }) : null,
    ticketValueFiltered = filters.ticketValue && selectedCards.filter(function (sc) {
      return ('' + sc.ticketValue).startsWith(filters.ticketValue);
    }),
    orderFiltered = filters.orderId && selectedCards.filter(function (sc) {
      return sc.orderId === filters.orderId;
    }),
    newSelectedCards = selectedCards;
  newSelectedCards = nameFiltered ? newSelectedCards.filter(function (c) {
    return nameFiltered.map(function (nf) {
      return nf.cardId;
    }).includes(c.cardId);
  }) : newSelectedCards;
  newSelectedCards = cnpFiltered ? newSelectedCards.filter(function (c) {
    return cnpFiltered.map(function (cf) {
      return cf.cardId;
    }).includes(c.cardId);
  }) : newSelectedCards;
  newSelectedCards = statusFiltered ? newSelectedCards.filter(function (c) {
    return statusFiltered.map(function (sf) {
      return sf.cardId;
    }).includes(c.cardId);
  }) : newSelectedCards;
  newSelectedCards = ticketValueFiltered ? newSelectedCards.filter(function (c) {
    return ticketValueFiltered.map(function (tvf) {
      return tvf.cardId;
    }).includes(c.cardId);
  }) : newSelectedCards;
  newSelectedCards = orderFiltered ? newSelectedCards.filter(function (c) {
    return orderFiltered.map(function (of) {
      return of.cardId;
    }).includes(c.cardId);
  }) : newSelectedCards;
  return newSelectedCards;
}
var filterByFirstLastName = function filterByFirstLastName(firstOrLastName) {
  return function (selectedCard) {
    var allNames = selectedCard.fullName.split(/[\s]+/);
    var firstName = allNames.slice(1).join(' ');
    var lastName = allNames.slice(0, 1).join('');
    return [firstName, lastName].filter(function (s) {
      return !!s;
    }).map(function (s) {
      return s.toLowerCase();
    }).some(function (namePart) {
      return namePart.startsWith(firstOrLastName);
    });
  };
};
export default card;