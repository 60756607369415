export var userRole = {
  ADMIN: 0,
  POWER: 1,
  STANDARD: 2,
  INROLARI_BULK: 3,
  properties: {
    0: 'Admin',
    1: 'Power',
    2: 'Standard',
    3: 'InrolariBulk'
  }
};
export var selectionType = {
  all: 0,
  multiple: 1,
  none: 2
};
export var cardStatus = {
  inProcess: 0,
  existing: 1,
  emitted: 2,
  confirmed: 3,
  deleted: 4,
  migrated: 5,
  reissued: 7,
  properties: {
    0: 'In procesare',
    1: 'Existent',
    2: 'Emis',
    3: 'Confirmat',
    4: 'Sters',
    5: 'Migrat',
    7: 'Reemis',
    8: 'Eroare'
  },
  getId: function getId(value) {
    for (var propName in this.properties) {
      if (this.properties[propName] === value) return propName;
    }
    return null;
  }
};
export var errorFilter = {
  all: 0,
  onlyErrors: 1
};
export var branchStatus = {
  disabled: 0,
  enabled: 1
};
export var fileUploadType = {
  cardsImport: 0,
  cardsDelete: 1,
  cardsImportAllBranches: 2,
  branchesImport: 3,
  cardsModifyTicketValue: 5,
  enrollmentFilesBulk: 6
};
export var invoiceStatus = {
  ACTIVE: 1,
  CANCELLED: 2,
  UNDEFINED: 3,
  properties: {
    1: 'Activa',
    2: 'Anulata',
    4: 'Stornata',
    5: 'Inactiva'
  }
};
export var enrollmentFileStatus = {
  AWAITS_SUPERVISION: 1,
  REJECTED: 2,
  CANCELLED: 7
};
export var enrollmentFileDetailsStatus = {
  AWAITING_PROCESSING: 1,
  PROCESSED: 2,
  ERROR_ALREADY_HAS_CARD: 3,
  ERROR_CANNOT_ISSUE_VIA_CHANNEL: 4,
  properties: {
    1: 'In procesare',
    2: 'Procesat',
    3: 'Eroare Angajatul tau are deja card la Banca Transilvania',
    4: 'Eroare Nu putem emite card pentru angajatul tau prin acest canal'
  }
};