import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { MANAGER_CREATE_FAILURE, MANAGER_CREATE_SUCCESS, MANAGER_HIDE_MODAL, MANAGER_SET_CURRENT_PAGE, MANAGER_SET_ERROR_MODAL, MANAGER_SET_PAGE_COUNT, MANAGER_SET_PAGE_SIZE, MANAGER_SET_SEARCH_TERM, MANAGER_SET_TOTAL_COUNT, MANAGER_SHOW_MODAL, MANAGER_UPDATE_FAILURE, MANAGER_UPDATE_SUCCESS, SET_CURRENT_MANAGER, SET_MANAGERS, SET_MANAGERS_LOADED_FLAG, SHOW_UPDATE_MANAGER_MODAL } from 'actions/manager';
export default function manager(state, action) {
  state = state || {
    managers: [],
    searchTerm: '',
    pageCount: 1,
    pageSize: 10,
    totalCount: 0,
    currentPage: 1,
    isModalVisible: false,
    modalServerError: ''
  };
  switch (action.type) {
    case SET_MANAGERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        managers: action.payload
      });
    case MANAGER_SET_SEARCH_TERM:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchTerm: action.payload
      });
    case MANAGER_SET_TOTAL_COUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        totalCount: action.payload
      });
    case MANAGER_SET_PAGE_COUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        pageCount: action.payload
      });
    case MANAGER_SET_PAGE_SIZE:
      return _objectSpread(_objectSpread({}, state), {}, {
        pageSize: action.payload
      });
    case MANAGER_SET_CURRENT_PAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentPage: action.payload
      });
    case MANAGER_SHOW_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isModalVisible: true,
        isModalInEditMode: false
      });
    case MANAGER_HIDE_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isModalVisible: false,
        isUpdateModalVisible: false,
        modalServerError: ''
      });
    case MANAGER_CREATE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        modalServerError: action.payload.errorMessage
      });
    case MANAGER_CREATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        modalServerError: '',
        isModalVisible: false
      });
    case MANAGER_UPDATE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        modalServerError: action.payload.errorMessage
      });
    case MANAGER_UPDATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        modalServerError: '',
        isUpdateModalVisible: false
      });
    case SHOW_UPDATE_MANAGER_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUpdateModalVisible: true,
        isModalInEditMode: true
      });
    case SET_CURRENT_MANAGER:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentManager: action.payload
      });
    case MANAGER_SET_ERROR_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        modalServerError: action.payload.errorMessage
      });
    case SET_MANAGERS_LOADED_FLAG:
      return _objectSpread(_objectSpread({}, state), {}, {
        managersLoaded: action.payload
      });
    default:
      return state;
  }
}