import { hideSapInvoiceLoadingIcon, SAP_INVOICE_FIND_FAILURE, SAP_INVOICE_FIND_SUCCESS, SAP_INVOICE_RECTIFY_REQUEST_FAIL, SAP_INVOICE_RECTIFY_REQUEST_SUCCESS, setPageCount, setSapInvoices, setTotalCount } from 'actions/sapInvoice';
import errorCodes from 'utils/errorCodes';
import { showToast } from 'actions/toast';
function sapInvoice(store) {
  return function (next) {
    return function (action) {
      switch (action.type) {
        case SAP_INVOICE_FIND_SUCCESS:
          {
            var pageSize = store.getState().sapInvoice.pageSize;
            var _action$payload = action.payload,
              sapInvoices = _action$payload.sapInvoices,
              totalCount = _action$payload.totalCount;
            var updatedSapInvoices = sapInvoices.map(function (i) {
              return {
                id: i.invoiceId,
                date: i.invoiceDate,
                title: i.title,
                description: i.description
              };
            });
            next(setSapInvoices([]));
            next(setSapInvoices(updatedSapInvoices));
            next(setPageCount(Math.ceil(totalCount / pageSize)));
            next(setTotalCount(totalCount));
            next(hideSapInvoiceLoadingIcon());
            break;
          }
        case SAP_INVOICE_FIND_FAILURE:
          {
            next(setSapInvoices([]));
            next(setPageCount(1));
            next(setTotalCount(0));
            next(hideSapInvoiceLoadingIcon());
            break;
          }
        case SAP_INVOICE_RECTIFY_REQUEST_SUCCESS:
          {
            next(showToast({
              type: 'success',
              text: 'Factura a fost trimisa spre corectare.'
            }));
            break;
          }
        case SAP_INVOICE_RECTIFY_REQUEST_FAIL:
          {
            next(showToast({
              type: 'error',
              text: errorCodes.genericErrorMessage
            }));
            break;
          }
      }
      var returnValue = next(action);
      return returnValue;
    };
  };
}
export default sapInvoice;