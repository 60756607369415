export var ORDER_FIND_REQUEST = 'ORDER_FIND_REQUEST';
export var ORDER_FIND_FAILURE = 'ORDER_FIND_FAILURE';
export var ORDER_FIND_SUCCESS = 'ORDER_FIND_SUCCESS';
export var ORDER_SET_ORDERS = 'ORDER_SET_ORDERS';
export var SET_ORDERS_LOADED_FLAG = 'SET_ORDERS_LOADED_FLAG';
export var findOrders = function findOrders(branchId, viewLastCreatedOrder) {
  return {
    type: ORDER_FIND_REQUEST,
    payload: {
      branchId: branchId,
      viewLastCreatedOrder: viewLastCreatedOrder
    }
  };
};
export var findOrdersFailure = function findOrdersFailure(errorMessage) {
  return {
    type: ORDER_FIND_FAILURE,
    payload: {
      errorMessage: errorMessage
    }
  };
};
export var findOrdersSuccess = function findOrdersSuccess(orders, viewLastCreatedOrder) {
  return {
    type: ORDER_FIND_SUCCESS,
    payload: {
      orders: orders,
      viewLastCreatedOrder: viewLastCreatedOrder
    }
  };
};
export var setOrders = function setOrders(orders) {
  return {
    type: ORDER_SET_ORDERS,
    payload: orders
  };
};
export var setOrdersLoadedFlag = function setOrdersLoadedFlag(loaded) {
  return {
    type: SET_ORDERS_LOADED_FLAG,
    payload: loaded
  };
};