export var TOAST_SHOW = 'TOAST_SHOW';
export var TOAST_HIDE = 'TOAST_HIDE';
export var showToast = function showToast(config) {
  return {
    type: TOAST_SHOW,
    payload: config
  };
};
export var hideToast = function hideToast() {
  return {
    type: TOAST_HIDE
  };
};