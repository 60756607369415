import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { setUploadError, uploadCsvFileRequest } from 'actions/upload';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UploadFileMealTicketScreen from './UploadFileMealTicketScreen';
import { withNavigate } from '../../withNavigate';
var mapStateToProps = function mapStateToProps(state) {
  return _objectSpread(_objectSpread(_objectSpread({}, state.upload), state.upload.config.uploadScreenBody), {}, {
    itemCorrection: _objectSpread({}, state.itemCorrection)
  });
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    uploadCsvFileRequest: uploadCsvFileRequest,
    setUploadError: setUploadError
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(UploadFileMealTicketScreen));